.tk-proxima-nova {
  font-family: 'proxima-nova', sans-serif;
}

.tk-museo-slab {
  font-family: 'museo-slab', serif;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.justify-content-space-around {
  justify-content: space-around;
}

.padd-left-right {
  padding: 0 32px 0 56px;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-stretch {
  align-items: stretch;
}

.full-height {
  height: 100%;
}

.relative {
  position: relative;
}

.none {
  display: none !important;
}

.flyout-container {
  padding: 0 32px;
}

.button--default-white {
  position: relative;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  padding: 8px 13px 8px 32px;
  font-size: 15px;
  font-weight: 600;
  color: #00829b;
  cursor: pointer;
}

.button--default-rest {
  height: 38px;
  display: inline-flex;
  background-color: #00829b;
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  border: none;
  color: #fff;
  padding: 9px 20px;
  border-radius: 3px;
  font-weight: 600;
  cursor: pointer;
}

.button--cancel:hover {
  background-color: #e3e4e8 !important;
}

.button--default-rest:not(:disabled):hover {
  background-color: #025c6d !important;
}

.button--default-gray {
  color: rgba(0, 1, 0, 0.8);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #f2f3f5;
  padding: 8px 11px;
  cursor: pointer;
}

.header--dark {
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.06);
  background-color: #232b2c;
}

.header--dark h1 {
  color: #ffffff;
  padding-bottom: 0;
}

.header--light h1 {
  color: #131414;
}

button:disabled,
input[type='submit']:disabled {
  opacity: 0.7;
}

.update-password-input:disabled {
  background-color: #abc0c4;
}

.button--default-rest:disabled {
  background-color: #abc0c4;
}

.real-room-settings {
  position: relative;
  border-radius: 300px;
  border: solid 2px #eaeaea;
  background-color: #ffffff;
  color: #00829b;
  font-size: 16px;
  font-weight: 600;
  padding: 9px 18px 9px 30px;
  cursor: pointer;
  margin-bottom: 32px;
}

.real-room-settings:hover {
  background-color: #d6e2e4;
}

.real-room-settings::before {
  content: '';
  width: 30px;
  height: 28px;
  display: inline-block;
  background-image: url('../../Images/Main/icon-settings.svg');
  background-repeat: no-repeat;
  background-position: center left;
  position: absolute;
  left: 5px;
  top: 4px;
}

.room--settings {
  position: relative;
  border-radius: 300px;
  border: solid 2px #eaeaea;
  background-color: #ffffff;
  color: #00829b;
  font-size: 16px;
  font-weight: 600;
  padding: 9px 18px 9px 30px;
  cursor: pointer;
}

.room--settings::before {
  content: '';
  width: 30px;
  height: 28px;
  display: inline-block;
  background-image: url('../../Images/Main/icon-back.svg');
  background-repeat: no-repeat;
  background-position: center left;
  position: absolute;
  left: 5px;
  top: 4px;
}

.error--message {
  text-align: center;
  padding: 15px 24px;
  margin-bottom: 30px;
  color: #2a2525;
  font-size: 15px;
}

.error--message span {
  font-weight: 300;
}

.auth-error {
  margin-top: 12px;
}

.success--message {
  text-align: center;
}

select:disabled {
  border: solid 2px #eaeaea !important;
  background-color: #eaeaea !important;
}

.rotate {
  transform: rotate(180deg);
}

.default-img {
  width: 100% !important;
  height: 100%;
}

.link--default {
  color: #00829b !important;
  font-weight: 600;
  line-height: 1.2;
  font-size: 15px;
  cursor: pointer;
}

.link--underlined {
  text-decoration: underline !important;
}

.link--default:active {
  color: #00829b;
}

.button--contextual-dark {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  border: solid 1px #d6e2e4;
  background-color: #f2f3f5;
  padding: 8px 39px 8px 13px;
  cursor: pointer;
}

.button--contextual-dark:hover {
  background-color: #e3e4e8 !important;
}

/* Change the white to any color ;) */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.no--bottom-margin {
  margin-bottom: 0 !important;
}

.margin-right-18 {
  margin-right: 18px;
}

input[type='checkbox']:disabled + .slider.round {
  border: solid 2px #abc0c4;
  background-color: #abc0c4;
}

.full-width {
  width: 100%;
}

.bottom-border {
  border-bottom: 2px solid #f2f2f2;
}

.top-border {
  border-top: 2px solid #f2f2f2;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}
