body {
  font-family: 'proxima-nova' !important;
}

table td {
  vertical-align: top;
}

table th {
  vertical-align: middle;
}

.signin {
  width: 33.3%;
  display: flex;
  align-items: center;
}

.signin > div:nth-child(1) {
  margin-top: 124px;
}

.signin > div {
  width: 100%;
}

.logo {
  width: 205px;
}

.logo img {
  width: 100%;
}

.auth-main {
  height: 100%;
}

.form-div {
  align-items: center;
}

.form-div .heading {
  padding-bottom: 12px;
}

.form-div .heading h2 {
  font-size: 35px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.01px;
  text-align: center;
  color: #131414;
}

.form-div .heading p {
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #727272;
  font-size: 20px;
  padding-top: 8px;
  width: 330px;
}

.form-div .checkmark ~ h3 {
  padding-left: 12px;
  color: #131414;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

h4 {
  padding: 24px 0 8px 0;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}

form {
  width: 100%;
  align-items: center;
}

.input-wrapper-login input {
  font-size: 18px;
  padding: 8px 14px 8px 14px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  width: 316px;
}

.input-wrapper-login {
  text-align: center;
}

.login--label {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #131414;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
      background-color: #fff !important;

} */

form > div.input-wrapper-login:nth-child(1) {
  margin-bottom: 26px;
}

.sub-register-wrapper {
  margin-top: 26px;
}

.submit-div {
  width: 100%;
  justify-content: center;
  padding: 44px 0 56px 0;
}

[class*='-submit'] {
  width: 140px;
  height: 46px;
  border-radius: 300px;
  background-color: #00829b;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}

[class*='-submit']:hover {
  background-color: #025c6d;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

[class*='-submit']:disabled {
  background-color: #abc0c4;
  box-shadow: none;
  cursor: not-allowedf;
}

.submit-revert {
  border: solid 2px #eaeaea;
  background-color: white;
  color: #00829b;
}

.submit-create-password {
  padding-top: 44px;
  padding-bottom: 14px;
}

.back-create-password {
  padding-bottom: 44px;
  padding-top: 0;
}

.keep-me {
  width: 100%;
}

.keep-me > div {
  justify-content: center;
  align-items: center;
}

.keep-me > div > input {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.26);
  background-color: rgba(255, 255, 255, 0.4);
}

a.forgot-password-link,
.register-link {
  font-size: 16px;
  font-weight: 600;
  padding: 26px 0 48px 0;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
  text-decoration: underline !important;
}

.register-link {
  padding: 26px 0 0px 0;
}

.copyright {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.copyright p {
  letter-spacing: normal;
  text-align: center;
  color: #727272;
  font-weight: normal;
}

.img-div {
  width: 66.6%;
  background: url('../../Images/login-background.jpg') no-repeat center center fixed;
  background-color: white;
  opacity: 1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(90, 93, 80, 0.83);
  height: auto;
  min-height: 100vh;
}

.forgot-password-wrapper + .img-div {
  height: 100vh;
}

.img-div img {
  width: 100%;
}

.img-div > div {
  width: 100%;
  background-color: red;
}

/* The container */

.container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
}

.container input {
  z-index: 10;
  opacity: 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin: 0;
}

.container input ~ span:hover {
  background-color: #eaeaea;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute !important;
  top: 3px;
  left: -2px;
  height: 21px;
  width: 21px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 3px;
  border: solid 2px #eaeaea;
}

.table-user thead tr th:first-child span .checkmark {
  top: -1px;
}

.form-div .checkmark {
  top: unset;
  left: unset;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .form-div .container .checkmark::after{
     top: 3px !important; 
     left: unset !important;
} */

/* When the checkbox is checked, add a blue background */

.container input:hover ~ .checkmark {
  background-color: #f2f3f5;
  border: solid 2px #eaeaea;
}

.container input:checked:hover ~ .checkmark {
  background-color: #0b6582;
}

.container input:checked ~ .checkmark {
  background-color: #00829b;
  border-color: #00829b;
}

.overview-campus select {
  padding: 5px 0 5px 11px !important;
}

/* .checkmark:hover{
      background-color: #025C6D;
} */

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.container .checkmark:after {
  width: 21px;
  height: 20px;
  background-image: url(../../Images/icon-checkbox-checked.png);
  color: #ffffff;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
}

.one-selected {
  background-color: #00829b !important;
  border-color: #00829b !important;
  background-image: url(../../Images/icon-uncheck-all.png);
  background-position: center;
  background-size: 80% 17px;
  background-repeat: no-repeat
}

.one-selected:hover{
  background-color: #0b6582 !important;
}

.login--input-hover:hover:not(:disabled) {
  background-color: #f2f3f5 !important;
}

.login--input-focus:focus {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16) !important;
  border: solid 2px #00829b !important;
  outline: none;
  background-color: #fff !important;
}

.login--input-invalid:invalid:not(:focus):not(:placeholder-shown) {
  border: solid 2px #fe2c12;
}

.custom-invalid {
  border: solid 2px #fe2c12 !important;
}

.validation-error-message {
  font-size: 14px;
  line-height: 1.29;
  padding-top: 10px;
  padding-left: 1px;
  display: none;
}

.login--input-invalid:invalid:not(:focus) ~ p {
  display: block;
  text-align: left;
  color: #fe2c12;
  /* font-family: Lato; */
  font-size: 15px;
}

.register-desc {
  text-align: center;
  line-height: 1.12;
  letter-spacing: normal;
  color: rgba(0, 1, 0, 0.8);
  font-size: 18px;
  margin-bottom: 36px;
  padding: 0 70px 0 70px;
}

.register-desc .link--default {
  font-size: 18px;
}

.notes textarea:disabled {
  border: solid 2px #eaeaea;
  background-color: #eaeaea;
}

@media only screen and (max-width: 1366px) {
  .auth-main {
    flex-direction: column;
    align-items: center;
  }
  .img-div {
    /* height: 139px;
            width: 100%;
            margin-top: 24px; */
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .img-div {
    display: block;
    position: absolute;
    visibility: hidden;
    overflow: hidden;
  }

  .img-div > div {
    visibility: visible;
  }

  .signin {
    width: 100%;
  }
  .input {
    padding: 10px 100px 9px 14px;
  }
  .filter-rows .filter-wrapper-item {
    max-width: 172px;
  }
}

@media only screen and (max-width: 1246px) {
  .filter-rows .filter-wrapper-item {
    max-width: 172px;
  }
}

@media only screen and (min-width: 1450px) {
  .filter-rows .filter-wrapper-item {
    max-width: 172px;
  }
}
