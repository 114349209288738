.admin-header {
  background-color: #ffffff;
  padding: 0 25px 0 25px;
  position: sticky;
  top: 0;
  z-index: 995;
  min-height: 71px;
  height: 7.7%;
  align-items: center;
  border-bottom: 1px solid #d6e2e4;
}

.standart-p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.readonly-p {
  width: 100%;
  max-width: 360px;
}

.icon-nav {
  width: 32px;
  display: none;
  height: 32px;
}

.user-account-list ul li {
  border: unset !important;
}

.floors thead tr th:first-child span:after {
  content: unset !important;
}

.floors thead tr th:first-child span .selectable-row:after {
  content: '' !important;
  background-image: url(../../Images/icon-checkbox-checked.png) !important;
  top: 0 !important;
}

/*.add-existing-kiosk h1{*/

/*      padding-bottom: 16px !important;*/

/*}*/

.add-existing-kiosk div .kiosk-group-actions {
  border-bottom: 2px solid #f2f2f2;
  padding: 16px 32px 32px;
  margin-bottom: 0;
}

.add-existing-kiosk div:nth-child(2) {
  padding: 0 !important;
}

.add-existing-kiosk div .header-v1-input-text {
  padding: 32px 32px 0 !important;
}

.building-reorder-buttons {
  display: unset !important;
}

.building-reorder-buttons a {
  float: left;
}

.temp-tracker-connect-inputs {
  padding-top: 32px;
}

.building-reorder-buttons .add-floor-button {
  float: right;
}

/* Custom react select styles */

.admin-menu-dropdown {
  background-color: transparent;
  border: none !important;
  outline: none;
}

.add-room-options {
  width: unset !important;
}

.tech-type {
  border-bottom: solid 2px #f2f2f2;
  margin-bottom: 32px;
}

.tech-type > .kiosk-type {
  margin-bottom: 0;
}

.flyout-header-v1 > .tech-type-remove-head {
  padding-bottom: 0;
}

.flyout-header-v1 > .tech-type-remove-head > p.standart-p {
  padding-bottom: 32px;
  border-bottom: 2px solid #f2f2f2;
}

.admin-menu-panel ul li {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  white-space: nowrap;
}

.confirmation-description p {
  font-size: 20px;
}

.user-table-list div:first-child table tbody tr td:nth-child(3) {
  width: 540px;
}

.connect-privotal-footer {
  display: flex;
  justify-content: flex-end;
}

.building-add-option {
  width: unset;
}

.building-add-option span {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  white-space: nowrap;
}

/* .admin-menu-panel ul li:nth-child(1n){
      padding: 25px 12px 13px;
} */

.admin-menu-panel ul div:nth-child(1),
.admin-menu-panel ul div:nth-child(2):not(:last-child) {
  border-bottom: 1px solid #d6e2e4;
}

.admin-menu-panel ul div:nth-child(1) {
  padding-bottom: 13px;
}

.admin-menu-panel ul div:nth-child(3) {
  padding-top: 13px;
}

.admin-menu-panel ul div:nth-child(2):not(:last-child) {
  padding: 13px 0;
}

.admin-menu-dropdown:active,
.admin-menu-dropdown:focus,
.admin-menu-dropdown > div:focus,
.admin-menu-dropdown > div:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.admin-menu-dropdown > div.react-dropdown-select-content input {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

.what-is-for-footer section {
  border-bottom: unset !important;
}

.admin-menu-dropdown > div.react-dropdown-select-content input::placeholder {
  opacity: 1;
}

/* Custom react select styles */

.admin-logo {
  height: 60px;
  width: 212px;
  position: relative;
}

.rooms-group-list div:first-child {
  padding: 0;
}

.admin-logo img {
  width: auto;
  height: 100%;
  cursor: pointer;
}

/* .sidebar-sub-menu {
      width: 110% !important;
} */

.admin-user-email {
  color: #131414;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: right;
}

.admin-user-email::after {
  content: '';
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url('../../Images/admin-menu-arrow-down.svg');
  position: relative;
  transition: transform 0.5s;
  top: -2px;
}

.admin-toggle-profile {
  position: relative;
  cursor: pointer;
}

.admin-menu-panel {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #eceff1;
  background-color: #ffffff;
  border-radius: 3px;
  position: absolute;
  top: 50px;
  width: 281px;
  right: 5px;
  z-index: 900;
}

.toogle-panel-top {
  bottom: 40px;
  top: unset;
}

.toogle-arrow-top::after {
  transform: rotate(180deg);
  border-right: solid 1px #d6e2e4;
  border-left: none !important;
  top: 0 !important;
  right: 0px !important;
}

.admin-user-email-active::after {
  transform: rotate(180deg);
}

.admin-menu-panel ul {
  padding: 12px 0;
  text-align: right;
}

.admin-menu-panel ul li {
  padding: 13px 13px 13px 13px;
}

.new-kiosk-mode h3 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.kiosk-detail-group .signage-list + ul li {
  margin-bottom: 32px;
}

.edit-kiosk-building h1 {
  padding-bottom: 32px !important;
  border-bottom: 2px solid #ebeced;
}

.group-assignment-section .admin-menu-panel ul li {
  padding: 13px 13px 13px 60px !important;
}

.admin-menu-panel ul li:hover {
  cursor: pointer;
  background-color: #ecf0f3;
}

/* Sidebar */

#sidebar {
  background-color: #2d464b;
  color: #2d464b;
  padding: 12px 0;
  flex: 0 0 21%;
  position: sticky;
  left: 0;
  top: 75px;
  max-width: 260px;
}

.sidebar-main-wrapper {
  height: 92.3%;
}

.sidebar-menu {
  flex-grow: 1;
}

.sidebar-menu > li > a {
  padding: 8px 0 8px 25px !important;
}

.sidebar-menu li a {
  cursor: pointer;
  text-align: left;
  padding: 15px 0 15px 25px;
}

.sidebar-menu li > div {
  padding: 8px 0 8px 25px;
  cursor: pointer;
  text-align: left;
}

.sidebar-sub-menu > li:hover,
.sidebar-menu > li:not(.sidebar-active) > div:hover,
.sidebar-menu > li:hover {
  background-color: rgba(236, 240, 243, 0.07);
}

.sidebar-menu li span {
  font-size: 18px;
  color: #ffffff;
  font-weight: 600;
}

.sidebar-menu li span img {
  width: 30px;
}

.sidebar-active div {
  background-color: #00829b;
}

.sidebar-active > a {
  background-color: #00829b;
}

.sidebar-menu li span:nth-child(1) {
  margin-right: 6px;
}

.sidebar-menu li.active-link {
  background-color: #00829b;
  text-decoration: none;
  background-image: url('../../Images/Sidebar/indicator-activepage.svg');
  background-position: 101.9% 50%;
  background-repeat: no-repeat;
}

li.active-sidebar-link {
  background-image: url('../../Images/Sidebar/indicator-activepage.svg');
  background-position: 101.9% 50%;
  background-repeat: no-repeat;
}

li.sidebar-active > a {
  background-image: url('../../Images/Sidebar/indicator-activepage.svg');
  background-position: 101.9% 50%;
  background-repeat: no-repeat;
}

.sidebar-sub-menu {
  width: 100%;
  max-height: 0;
  height: auto;
  /* position: relative;
      left: -26px; */
  overflow: hidden;
  transition: max-height 0.5s;
}

.sidebar-active > a ~ ul,
.sidebar-active > div ~ ul {
  max-height: 280px;
  background-color: #223539;
  padding-top: 12px;
  padding-bottom: 12px;
}

.sidebar-active > a ~ ul span,
.sidebar-active > div ~ ul span {
  font-weight: normal;
  font-size: 16px;
}

.active-sidebar-link span {
  font-weight: bold !important;
}

.sidebar-sub-menu li a {
  padding-left: 56px;
}

/* .sidebar-sub-menu li.active-sidebar-link {
      background-color: rgb(100, 199, 217, 0.24);
} */

.sidebar-sub-menu li.active-sidebar-link a span {
  color: #14b7d6;
}

.sidebar-settings {
  border-top: 1px solid #65787b;
  width: 100%;
  padding-top: 12px;
}

.admin-wrapper {
  height: 100vh;
  position: relative;
  /* overflow: hidden; */
}

/* Main */

#main {
  position: relative;
  background-color: #ebeced;
  flex: 1 0 76%;
  padding: 40px 0 0 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* .overlay-active-nav {
      position: absolute;
      width: 58%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 996;
      overflow: hidden;
      top: 77px;
      left: 259px;
} */

.main-footer {
  flex-shrink: 0;
  background-color: #232b2c;
  position: relative;
  margin-top: auto;
}

.main-footer .container {
  padding: 24px 24px;
}

.main-footer .copyright {
  font-size: 18px;
  font-weight: 400;
  text-align: right;
  color: #cbcbcb;
}

.copyright a {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #727272;
  margin-top: 7px;
  text-decoration: underline;
}

/* flyout */

.flyout-transition-enter {
  right: -100% !important;
}

.flyout-transition-enter.flyout-transition-enter-active {
  right: 0 !important;
  transition: right 200ms;
}

.flyout-transition-exit {
  right: 0 !important;
}

.flyout-transition-exit.flyout-transition-exit-active {
  right: -100% !important;
  transition: right 200ms;
}

/* Opacity transitions */

.flyout-transition-opacity-enter {
  opacity: 0 !important;
}

.flyout-transition-opacity-enter.flyout-transition-opacity-enter-active {
  opacity: 1 !important;
  transition: opacity 200ms;
}

.flyout-transition-opacity-exit {
  opacity: 1 !important;
}

.flyout-transition-opacity-exit.flyout-transition-opacity-exit-active {
  opacity: 0 !important;
  transition: opacity 200ms;
}

/* Opacity transitions */

#flyout {
  position: fixed;
  top: 0;
  right: 0;
  width: 640px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.06);
  z-index: 996;
  overflow-y: auto;
}

.overlay {
  height: 100%;
  overflow: hidden;
}

.overlay-active {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 996;
  overflow: hidden;
}

.overlay-snackbar-active {
  background: unset;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 996;
  position: absolute;
  overflow: hidden;
}

.flyout-header-v1 h1 {
  padding: 32px 32px 0 32px;
}

.flyout-header-v1.header--dark h1 {
  padding: 32px 32px 25px 32px;
}

.flyout-header-v1 > .flyout-nth-section {
  padding-top: 31px;
  border-top: solid 2px #f2f2f2;
}

.flyout-nth-section h1 {
  padding: 0;
  padding-bottom: 32px;
}

.flyout-nth-section > .upload-btn,
.upload-btn {
  font-size: 15px !important;
  padding: 8px 11px 7px 0px !important;
  max-width: fit-content;
  border: solid 1px #d6e2e4 !important;
}

.flyout-nth-section > .upload-btn {
  margin-bottom: 24px;
}

.upload-btn.without-icon {
  padding-left: 11px !important;
}

.add-building > div {
  border-bottom: none;
}

.adding-info {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.01px;
  color: #000000;
}

.flyout-header-v1 h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
}

.flyout-header-v1 h1 ~ div {
  padding: 32px;
}

.header-v1-input-text {
  padding-bottom: 32px;
  border-bottom: 2px solid rgba(58, 58, 58, 0.06);
  margin-bottom: 32px;
}

.header-v1-input-text input {
  padding: 5px 8px 5px 8px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  color: #131414;
  font-size: 15px;
  width: 341px;
}

.header-v1-input-text input::placeholder {
  opacity: 0.5;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.header-v1-input-text input:disabled {
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: #eaeaea;
}

.header-v1-input-text label {
  font-size: 20px;
  text-align: left;
  color: #131414;
  font-weight: 600;
  line-height: 1.3;
}

.sub-name {
  padding-top: 32px;
  border: none;
}

.user-actions button.reset-user-password::before {
  background-image: url('../../Images/flyout/icon-resetpass.png');
}

.invite-user-sso {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.suggested-users {
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  padding: 16px 0;
  position: absolute;
  top: 172px;
  max-height: 359px;
  min-width: 360px;
}

.suggested-users div.single-user-block {
  padding: 14px 12px;
  cursor: pointer;
}

.suggested-users div.single-user-block:hover {
  background-color: #ecf0f3;
}

.suggested-users p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.suggested-users span {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
  margin-top: 2px;
}

.roles-container {
  padding-top: 0;
  padding-bottom: 40px;
  /* border-bottom: solid 2px #f2f2f2; */
}

.selects {
  max-height: 250px;
  overflow-y: auto;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.select-item > div {
  padding: 16px 11px;
}

#multiple-select {
  width: 360px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  margin-bottom: 132px;
}

#multiple-select > div {
  border-radius: 3px;
  background-color: #ffffff;
  padding: 13px;
  border-bottom: 1px solid #d6e2e4;
}

#multiple-select > div.selected {
  padding: 24px 11px;
}

#multiple-select .select-item > div {
  padding-left: 0;
}

#multiple-select .select-all p {
  margin-left: 12px;
}

.role-name {
  margin-bottom: 23px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.invite-user-header {
  padding-bottom: 0;
}

.roles-flyout > div:not(:last-child) {
  padding-bottom: 32px;
}

.roles-heading {
  height: 40px;
}

.roles-heading p {
  color: #222222;
  font-size: 20px;
  font-weight: 600;
}

.roles-heading button {
  color: rgba(0, 1, 0, 0.8);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: normal;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #f2f3f5;
  padding: 8px 11px;
  cursor: pointer;
}

.roles-heading button:hover {
  background-color: #e3e4e8;
}

.current-roles > div:not(:last-child) {
  padding-bottom: 40px;
}

.current-roles div.role p {
  font-size: 20px;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
  font-weight: 300;
  padding-bottom: 13px;
  line-height: 1.3;
}

.current-roles div.role span {
  color: #00829b;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.flyout-header h1 {
  font-size: 28px;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  font-weight: 600;
}

.flyout-user-options {
  border-bottom: 2px solid #ebeced;
  padding-bottom: 32px;
  padding-top: 32px;
  flex-wrap: wrap;
}

.flyout-user-options .email-address {
  text-align: left;
  color: #131414;
  font-size: 20px;
}

.flyout-user-options .email-address p {
  letter-spacing: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
  color: #131414;
}

.flyout-user-options .email-address span {
  letter-spacing: 0.01px;
  font-weight: 300;
  margin-top: 8px;
}

.flyout-user-options .change-password a {
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #f2f3f5;
  padding: 9px 11px;
  position: relative;
  top: 6px;
  font-size: 15px;
  font-weight: 600;
  color: #131414;
}

.flyout-user-options .change-password a:hover {
  background-color: #e3e4e8;
}

.flyout-user-options .change-password a::before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url('../../Images/flyout/icon-resetpass.png');
  background-repeat: no-repeat;
  vertical-align: middle;
  background-size: 30px;
}

.flyout-footer-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.flyout-footer {
  padding: 32px;
  border-top: 2px solid #ebeced;
  height: 36px;
}

.flyout-footer .button--default-rest {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: normal;
  font-family: 'proxima-nova';
}

.add-room-email-remainder {
  font-family: ProximaNova;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.flyout-footer .button--cancel {
  margin-right: 16px;
}

.flyout-footer.roles-editor {
  position: fixed;
  background: white;
  bottom: 0;
  width: 576px;
  z-index: 6;
  height: 36px;
}

.assign-room-header .pairDevice-header {
  border-bottom: 2px solid #f2f2f2;
}

.assign-room-header .set-devicinfo {
  padding-top: 32px;
  border-bottom: unset;
}

.assign-room-header .edit-admin {
  padding-bottom: 32px;
}

.assign-room-header .edit-admin {
  border-bottom: unset !important;
  margin: unset;
}

.campuses-roles-wrapper h2 {
  margin-bottom: 8px;
}

.role-text {
  border-bottom: solid 2px #f2f2f2;
}

.slide-add-flyout {
  padding: 0 !important;
}

.slide-image-detail {
  width: 184px;
  height: 116px;
}

.slide-image-detail img {
  width: 100%;
  height: 100%;
}

.detail-content-info {
  margin-left: 32px;
}

.signage-list + ul {
  margin-top: 22px;
}

.signage-list h1 {
  font-size: 20px;
  text-align: left;
  color: #222222;
  font-weight: 600;
}

.signage-list + ul li {
  margin-bottom: 5px;
  font-size: 20px;
  text-align: left;
  color: #000000;
  font-weight: 300;
}

.signage-list + ul li:not(:last-child) {
  margin-bottom: 32px;
}

.detail-content-info p {
  text-align: left;
  color: #000000;
  font-size: 20px;
  font-weight: 300;
}

.signage-assinments-details {
  margin-bottom: 24px;
}

.assignment-info p {
  margin-top: 22px;
  margin-bottom: 6px;
  font-size: 17px;
}

.assignment-info span {
  color: #727272;
  font-size: 15px;
}

.signage-assinments-details p {
  font-size: 20px;
  color: #000000;
  margin-bottom: 13px;
}

.signage-assinments-details span {
  color: #727272;
  font-size: 15px;
}

.detail-content-info span {
  font-size: 15px;
  text-align: left;
  color: #727272;
}

.messaging-flyout-slide {
  margin-bottom: 100px;
  border-bottom: none !important;
}

.flyout-footer.roles-editor a {
  margin-left: 16px;
}

/* Flyout change password */

.flyout-change-password-wrapper {
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}

.flyout-change-password-container {
  padding: 32px;
}

.padd32 .signage-list {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.flyout-change-password-container .header {
  padding-bottom: 32px;
}

.flyout-change-password-container .header h1 {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.kiosk-configuration-mode label {
  font-size: 19px !important;
}

div.current-password {
  padding-bottom: 32px;
  border-bottom: 2px solid #eaeaea;
}

.flyout-change-password-container div.change-passowrd-wrapper-input:not(:last-child) {
  margin-bottom: 24px;
}

.signage-group-table .header-table div:first-child {
  align-items: center;
}

.flyout-change-password-container .change-password-input {
  display: flex;
  flex-direction: column;
}

.flyout-change-password-container .change-password-label {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.flyout-change-password-container .change-password-input {
  width: 360px;
  margin-top: 8px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 8px 0 8px 11px;
}

div.new-password {
  padding: 32px 0;
  border-bottom: 2px solid #eaeaea;
}

.flyout-change-password-footer {
  display: flex;
  justify-content: flex-end;
}

.flyout-change-password-footer > div:not(:last-child) {
  margin-right: 16px;
}

.flyout-change-password-footer .cancel button {
  border-radius: 3px;
  border: solid 2px #d6e2e4;
  background-color: #f2f3f5;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #131414;
  padding: 8px 20px;
  cursor: pointer;
}

.flyout-change-password-footer .cancel button:hover {
  background-color: #e3e4e8;
}

.update-password-input {
  font-size: 16px;
  padding: 8px 20px;
  border-radius: 3px;
  color: white;
  background-color: #00829b;
  border: solid 2px #d6e2e4;
  font-weight: 600;
  letter-spacing: normal;
  cursor: pointer;
  outline: none;
  line-height: 1.19;
}

.update-password-input:hover:enabled {
  background-color: #025c6d;
}

/* Snackbar */

#snackbar {
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 997;
  padding-bottom: 32px;
}

#snackbar.snackbar-toggle-color {
  animation: toggleBorder 4s;
}

#snackbar .snackbar-container {
  padding: 20px 30px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  background-color: #2b2d2d;
  position: fixed;
  bottom: 106px;
}

.snackbar-container p {
  font-size: 15px;
  font-style: normal;
  letter-spacing: normal;
  font-weight: normal;
  text-align: left;
  color: rgba(255, 255, 255, 0.9);
  margin-right: 32px;
  line-height: 1.33;
}

.snackbar-container button {
  background: transparent;
  border: none;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  color: #1db4d1;
  line-height: 1.33;
  cursor: pointer;
}

/* User table */

.table-subheading {
  letter-spacing: 0.01px;
  line-height: 1.3;
  text-align: left;
  font-size: 20px;
  color: #000000;
  margin-bottom: 16px;
  font-weight: 300;
}

.custom-elipsis-multiline {
  display: -webkit-box;
  max-width: 250px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* main.user-table {
      width: 90%;
      margin: 0 auto;
      flex: 1 0 auto;
}
 */

main.user-table {
  width: 80%;
  margin: 0 auto;
  flex: 1 0 auto;
  max-width: 1280px;
  margin-bottom: 64px;
}

main.user-table img {
  width: unset;
}

.invite-user-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}

.invite-user-wrapper img {
  height: 30px;
  margin-left: 8px;
}

main.user-table h1 {
  font-size: 46px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.01px;
  text-align: left;
  color: #131414;
}

main.user-table > hr {
  border-bottom: 2px solid #f2f2f2;
}

.header-table {
  background-color: #fbfbfb;
  border-bottom: 1px solid #aeafb1;
  margin-top: 16px;
}

.signage-overview-section .overview-section-footer {
  padding: 0 !important;
}

.signage-slides-wrapper h2 {
  padding-bottom: 6px;
}

.slides-button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 16px;
}

.header-table p {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
}

.header-table > div {
  padding: 24px !important;
}

.header-table input:not(.input-style) {
  width: 360px;
  padding: 6px 0 6px 11px;
  background-image: url('../../Images/UsersTable/btn-search.svg');
  background-repeat: no-repeat;
  background-position: 100%;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  outline: none;
}

.header-table ::placeholder {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.header-table a[href*='/filter'] {
  border: solid 2px #eaeaea;
}

.new-user a > div {
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  color: #00829b;
  cursor: pointer;
}

.new-user a > div:first-of-type {
  padding: 6.5px 17px 6.3px 17px;
  margin-right: 17px;
}

.user-list .new-user a > div:first-of-type {
  margin-right: 0;
}

.section-table {
  background-color: #ffffff;
}

.section-table > div {
  position: relative;
  padding: 12px 24px 36px 24px !important;
}

.table-user {
  width: 100%;
}

.table-rooms table tbody tr td:nth-child(2) {
  width: 350px;
}

.add-slide-link {
  margin-right: 0 !important;
}

.kiosk-group-section header div div:first-child {
  padding: 0 !important;
}

.kiosk-group-section header div div:nth-child(2) {
  padding: 0 !important;
}

.room-email {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.signage-overview-section form div:first-child .cont-div {
  max-width: 384px;
}

.table-user h2 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
}

.table-user h3 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.table-user tr {
  /* display: flex;
      align-items: center; */
  border-bottom: 2px solid #f2f2f2;
  cursor: pointer;
  text-align: left;
}

.table-user tr:hover {
  background-color: #fbfbfb;
}

.table-user th span::after {
  content: '';
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  position: absolute;
  /* top: -5px; */
}

.table-user th:first-child .selectable-row {
  left: 10px !important;
}

.desc-sorted::after {
  background-image: url('../../Images/Main/icon-dropdown.svg');
  transform: rotate(0);
}

.asc-sorted::after {
  background-image: url('../../Images/Main/icon-dropdown.svg');
  transform: rotate(180deg);
}

.table-user th:first-child .selected-table {
  position: relative;
  left: 0px;
}

.table-user th span {
  position: relative;
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: left;
  color: #404040;
  text-transform: uppercase;
}

.table-user th,
td {
  padding: 18px 0 16px 0;
  font-size: 18px;
  color: #222222;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
}

.table-user .selectable-wrapper th:first-child span {
  position: relative;
  left: -12px;
}

/* .table-user th:first-child .not-selected {
  left: 0 !important;
} */

.table-user td {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  vertical-align: top;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
}

.building-lanidng_table .table-user :is(:is(th, td):not(:first-child)) {
  padding-left: 20px;
}

#floors td {
  color: #00829b;
}

.room-settings-flyout-content h4 {
  padding-top: 32px;
  padding-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: #222222;
}

.room-settings-flyout-content > div > a > span {
  display: block;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  color: #00829b;
  padding-top: 13px;
  cursor: pointer;
}

.room-settings-flyout-content div:first-child h4 {
  padding-bottom: 22px;
}

.room-settings-flyout-content hr {
  margin-top: 32px;
  margin-bottom: 0;
  border: solid 2px #f2f2f2;
}

.room-settings-flyout-content p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
}

.building-assign tr td:nth-child(2),
.room-groups-of-room table tr td:nth-child(2) {
  color: #00829b;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
}

.building-assign tr td:nth-child(3),
.building-assign tr td:nth-child(4) {
  font-size: 18px !important;
}

#rooms td {
  font-size: 18px;
  vertical-align: middle;
}

.supportLogs .border-bot2 {
  padding-bottom: 0 !important;
}

.table-user th {
  /* width: 22%; */
  text-align: left;
  vertical-align: middle;
}

.th-width {
  width: 22%;
}

.table-user th img {
  position: relative;
  bottom: -7px;
}

.table-user .checkbox-wrapper {
  width: 2%;
}

.table-reusable .checkbox-wrapper {
  display: none;
}

.highlight-row td:nth-child(1) {
  color: #00829b;
  text-decoration: underline;
  font-weight: 600;
}

.selectable-wrapper th:nth-child(1),
.selectable-wrapper td:nth-child(1) {
  width: 5%;
}

.selectable .checkbox-wrapper {
  display: table-cell;
  padding-right: 17px;
  vertical-align: middle;
}

.selectable .checkbox-table .checkmark {
  top: unset;
  left: unset;
}

.confirmation-desc {
  margin-bottom: 25px;
  line-height: 1.2;
  font-weight: 300;
  color: #000000;
}

.confirmation-desc + a {
  font-size: 20px;
  letter-spacing: normal;
}

.selectable .table-heading .checkbox-table .checkmark {
  opacity: 0.5;
  display: none;
}

.selectable .table-heading .checkbox-table .checkmark::after {
  left: 11px;
  width: 0;
  transform: rotate(90deg);
}

.section-table .previous-next {
  width: 100%;
  padding: 24px 0 0 0 !important;
  background-color: #ebeced;
}

.previous-next li {
  padding: 9px 16px;
  border: solid 1px #c5d0d2;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #00829b !important;
  background-color: #ebeced;
}

#rooms-section .rooms-section-footer {
  padding: 0;
}

.section-table-data {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
}

.previous-next .page {
  color: #131414;
}

.previous-next .active {
  color: #131414 !important;
}

.previous-next .page.active {
  background-color: #00829b;
  color: #ffffff !important;
}

.previous-next .page:hover {
  background-color: #00829b;
  color: #131414 !important;
}

.double-row-main p:nth-child(1) {
  font-size: 20px;
  color: #00829b;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 6px;
}

.ununderlined-row p:first-child {
  text-decoration: none;
}

.double-row-main p:nth-child(2) {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.double-row-main.workspace-type-row {
  min-width: 320px;
  width: 100%;
}

.double-row-readonly p:nth-child(1) {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.double-row-readonly p:nth-child(2) {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.double-row-secondary p:nth-child(1) {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.double-row-secondary p,
.default-row p {
  font-size: 18px;
  margin-top: 6px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
}

.double-row-secondary p:nth-child(2) {
  color: #727272;
}

.table-user tr:last-child {
  border: unset !important;
}

.device-status p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.btn-edit {
  font-size: 15px;
  font-weight: 600;
  color: #00829b;
  cursor: pointer;
}

.flyout-content_header-description-btn {
  padding-top: 32px;
  padding-bottom: 39px;
  border-bottom: 2px solid #f2f2f2;
  display: flex;
  flex-direction: column;
}

.flyout-content_header-description-btn > a {
  margin-top: 13px;
}

.flyout-content_header-description-btn > span {
  padding-bottom: 9px;
  margin-bottom: 22px;
}

.flyout-content_header-description {
  padding-top: 31px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Rooms table */

.new-room {
  position: relative;
}

.new-room button:hover {
  background-color: #f2f3f5;
}

.new-user a div:hover {
  background-color: #f2f3f5;
}

.add-room-options {
  width: 238px;
  right: 0;
}

.add-assingment-option {
  top: 37px;
  right: 0;
}

.add-room,
.digital-signage-button {
  position: relative;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  padding: 8px 11px 8px 11px;
  color: #00829b;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  margin-right: 17px;
}

.group-assignment-section button {
  margin-right: 0 !important;
}

.toggle-room-adding-options {
  padding-right: 35px;
}

.toggle-adding-options {
  padding-left: 25px;
  width: 100%;
}

.toggle-reordering-options {
  padding-left: 25px;
  margin-left: 20px;
  width: 100%;
}

.toggle-reordering-options:hover {
  background-color: #f2f3f5;
}

.add-room.toggle-adding-options::before {
  content: '';
  width: 25px;
  height: 34px;
  display: inline-block;
  background-image: url('../../Images/Main/icon-add.svg');
  background-repeat: no-repeat;
  background-position: center right;
  position: absolute;
  top: 0;
  left: 6px;
}

.add-room.toggle-reordering-options::before {
  content: '';
  width: 25px;
  height: 34px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center right;
  position: absolute;
  top: 0;
  left: 6px;
}

.add-room.toggle-room-adding-options::after,
.toggle-add-assignment::after {
  content: '';
  width: 25px;
  height: 34px;
  display: inline-block;
  background-image: url('../../Images/Main/icon-dropdown.svg');
  background-repeat: no-repeat;
  background-position: center right;
  border-left: solid 1px #d6e2e4;
  position: absolute;
  top: 0;
  right: 2px;
}

.margin-right {
  margin-right: 15px;
}

reorderFloors-div span {
  color: #131414;
  font-size: 18px;
}

/* Filter */

.header-filter > div {
  padding: 32px;
  border-bottom: 2px solid #f2f2f2;
}

.section-table .table-user tr {
  border-bottom: 2px solid #f2f2f2 !important;
}

.header-filter > div button {
  margin-top: 16px;
  max-width: 100px;
  padding: 7px 11px 6px 0;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #00829b;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
}

.header-filter > div button:hover {
  background-color: #f2f3f5;
}

.header-filter > div button::before {
  content: '';
  width: 30px;
  height: 28px;
  display: inline-block;
  background-image: url('../../Images/Main/icon-reset.png');
  background-repeat: no-repeat;
  background-position: center left;
  position: absolute;
  background-size: 30px;
  left: 1px;
  top: 2px;
}

.header-filter h1 {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.section-filter > div {
  padding: 0 32px;
}

.section-filter input {
  width: 24px;
  height: 24px;
}

.section-filter h3 {
  font-size: 20px;
  padding-bottom: 12px;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.section-filter p {
  font-size: 18px;
  padding-left: 12px;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.section-filter form > div > div {
  padding: 32px 0;
  border-bottom: 2px solid #f2f2f2;
}

.section-filter form > div > div > div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.section-filter form > div > div > div > div {
  margin-bottom: 12px;
}

.footer-filter > div {
  padding: 32px;
}

.footer-filter input {
  padding: 9px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  background-color: #00829b;
  border-radius: 3px;
}

/* User roles related */

.edit-role {
  width: 35%;
  background-color: #ffffff;
}

.remove-role {
  margin-top: 15px;
  margin-left: 32px;
}

.edit-header {
  width: 100%;
  border-bottom: 2px solid #f2f2f2;
}

.edit-header h1 {
  font-size: 28px;
  padding-bottom: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.edit-header input {
  background-image: url('../../Images/UsersTable/icon-delete.png');
  background-repeat: no-repeat;
  background-position: 2%;
  cursor: pointer;
  padding: 8px 13px 7px 32px;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
}

.edit-header > div {
  padding: 32px 32px 32px 32px;
}

.edit-section {
  border-bottom: 2px solid #f2f2f2;
}

.edit-section > div {
  padding: 32px 32px 32px 32px;
}

.edit-admin h2 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding-bottom: 8px;
}

.edit-admin select {
  width: 360px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding: 4.5px 0 4.5px 11px;
}

.edit-admin > div:nth-of-type(2) {
  padding-bottom: 24px;
}

.edit-admin {
  border-bottom: none;
  padding-bottom: 0;
}

.edit-admin p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
  padding-bottom: 32px;
  margin-bottom: 32px;
}

.edit-assign {
  padding-top: 32px;
}

.edit-assign > div {
  padding-bottom: 24px;
}

.edit-assign > div:last-of-type {
  padding-bottom: 126px;
}

.edit-assign h2 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
  padding-bottom: 8px;
}

.edit-assign h3 {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.edit-assign > div:nth-of-type(1) h2 {
  padding-bottom: 12px;
}

.current-selected-role-wrapper {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.current-selected-role-wrapper input {
  background-image: url(../../Images/Main/icon-dropdown.svg);
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 100%;
  width: 330px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.user-page-flyout {
  padding-top: 32px;
}

.selected ul li:not(:last-child) {
  margin-bottom: 5px;
}

.edit-assign input:not(.current-selected-role) {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.edit-assign select {
  width: 360px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding: 7px 0 8px 11px;
}

.edit-footer > div {
  padding: 32px;
}

.edit-footer input:last-of-type {
  padding: 9px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  background-color: #00829b;
  border-radius: 3px;
}

.edit-footer input:first-of-type {
  padding: 9px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #131413;
  background-color: #f2f3f5;
  border-radius: 3px;
  margin-right: 16px;
}

/* 404 not found */

.error-main {
  width: 100%;
  background-color: #ebeced;
}

.error-main > div {
  padding-left: 190px;
}

.error-section h1 {
  font-size: 46px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.01px;
  text-align: left;
  color: #131414;
}

.error-section p {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.01px;
  text-align: left;
  padding: 16px 0;
  margin-bottom: 29px;
}

.error-section h3 {
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding: 16px 0;
}

.error-section a {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
}

.error-footer {
  width: 100%;
  position: relative;
  bottom: 0;
}

.error-footer img {
  width: 100%;
  position: relative;
  bottom: -3px;
}

/* loading */

.lds-roller {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flyout-loader {
  height: 80%;
  width: 85%;
}

.flyout-loader-v2 {
  height: 50vh;
}

.table-loader {
  height: 65px;
  width: 85%;
  align-items: flex-start;
}

.all-rows {
  position: relative;
  left: 14px;
}

.signin .loader-wrapper {
  margin-right: 125px;
  margin-bottom: 125px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
  background-color: #00829b;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

.suggested-users-loader {
  width: 75%;
  max-height: inherit;
}

.suggested-users-loader > div {
  margin-bottom: 90px;
}

.overview-main {
  max-width: 100%;
  background-color: #ebeced;
  padding: 0 16px;
}

.overview-main > div {
  max-width: 1280px;
  margin: 0 auto;
}

.overview-h2 h2 {
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.overview-section {
  background-color: #ffffff;
  margin-bottom: 24px;
}

.overview-section form > div:first-child {
  padding: 0 24px;
}

.overview-campus {
  background-color: #fbfbfb;
}

.overview-section form > div > div {
  padding: 32px 0;
}

.overview-name-input {
  max-width: 384px;
}

.overview-section h3 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin-bottom: 8px;
}

.cancel-overview {
  margin-right: 16px;
}

.addres input {
  background-image: url('../../Images/UsersTable/btn-search.svg');
  background-repeat: no-repeat;
  background-position: 100%;
}

.bor-bot {
  border-bottom: 1px solid #d6e2e4;
}

.overview-section-footer > div {
  padding: 24px 0 !important;
}

.overview-section p:not(.error--message) {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding-top: 7px;
}

.cont-div input {
  height: 13px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 7px 30px 8px 11px;
  font-size: 15px;
  text-align: left;
  color: #131414;
  max-width: 384px;
}

.cont-div > div:not(:last-child) {
  margin-right: 32px;
}

.checkbox-table {
  margin-left: 12px;
}

.signage-group-assigned thead .table-heading th,
.productLicensing .table-heading th {
  width: unset !important;
}

.table-user th:first-child input:checked ~ .checkmark:after {
  content: '';
  top: 1px;
}

.kiosk-select {
  max-width: 798px;
}

.workspace-assignments .kiosk-select {
  max-width: unset;
}

.image-section-container {
  gap: 62px;
  text-align: center;
  border-bottom: solid 1px #d6e2e4;
  background-color: #fbfbfb;
  margin-left: -23px;
  margin-right: -23px;
  padding: 32px 23px !important;
  border-top: solid 1px #d6e2e4;
}

.image-section-container > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.image-section-container > div:first-child > div {
  max-width: 360px;
  width: 100%;
  max-height: 155.8px;
  height: 100%;
}

.image-section-container > div:first-child > span {
  text-align: start;
}

.image-section-container > div:first-child > div > img {
  width: 100%;
  height: 100%;
}

.image-section-container > div:last-child {
  gap: 24px;
  margin-top: 27px;
}

.update-workspace_header {
  border-bottom: solid 2px #f2f2f2;
}

.update-workspace_main {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 31px 32px;
  margin-bottom: 95px;
}

.update-workspace_btn-containter {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 31px;
}

.update-workspace_btn-containter > div:first-child {
  display: flex;
  gap: 16px;
}

.update-workspace_main > div:last-child {
  display: flex;
  justify-content: flex-start;
  margin-top: 8.5px;
}

.update-workspce_image-section-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.update-workspce_image-section-container > div {
  position: relative;
  max-width: 270px;
  max-height: 121.2px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex: 0 0 48%;
}

.update-workspce_image-section-container div img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.update-workspce_image-section-container div:first-child img {
  object-fit: contain;
}

/* .update-workspce_image-section-container div:first-child::before { */
/* position: absolute; */
/* go outside padding-box by 
   * a border-width ($b) in every direction */
/* top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  content: url("../../../../Admin/Includes/Main/Inners/Offices/Workspaces/Assets/placeholder.svg");
  transform: translate(87%, 5px);
  z-index: 1;
  width: 78px;
  height: 78px; */
/* } */

.edit-workspace-image_main {
  background-color: #1d1f21;
  width: 100%;
  height: 100vh;
  margin-top: 32px;
  padding: 0 !important;
  margin-bottom: 32px !important;
}

.edit-workspace-image_image-and-desc-container {
  padding-top: 64px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24.2px;
  margin-bottom: 32px;
}

.edit-workspace-image_image-and-desc-container > div:first-child {
  max-height: 155.6px;
  max-width: 360px;
  width: 100%;
  height: 100%;
  margin: auto;
  padding-top: 64px;
}

.edit-workspace-image_image-and-desc-container > div:first-child img {
  width: 100%;
  height: 100%;
}

/* .edit-workspace-image_image-and-desc-container > div:first-child::after {
  content: "";
  position: absolute;
  max-height: 155.6px;
  max-width: 360px;
  width: 100%;
  height: 100%;
  border: solid 2px #fff;
  border-radius: 6px;
  transform: translateX(-100%);
  box-sizing: border-box;
} */

.edit-workspace-image_image-and-desc-container pre {
  font-size: 15px;
  color: #fff;
  text-align: center;
}

.edit-workspace-image_range-container {
  padding: 0 32px;
}

.edit-workspace-image_range-container > div:first-child {
  display: flex;
  justify-content: space-between;
  padding-bottom: 14px;
}

.edit-workspace-image_range-container > div:first-child p {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
}

.button-inside-link {
  width: fit-content;
}

.kiosk-select div:first-child {
  margin-right: 16px;
}

.kiosk-select div:nth-child(2) {
  margin-left: 16px;
  padding-left: 0;
}

.kiosk-overview .kiosk-select > div {
  max-width: 384px;
}

.signage-group-assigned tbody tr td:first-child {
  color: #00829b !important;
}

.signage-kiosk-group {
  margin-bottom: 64px;
}

.signage-group-assigned tbody tr td:nth-child(2) {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.room-groups-of-room th:first-child {
  width: 4% !important;
}

.building-assign th span:after {
  content: '';
  background-image: url('../../Images/Main/icon-dropdown.svg');
  top: -5px;
}

#floors section th span:after {
  content: '';
  background-image: url('../../Images/Main/icon-dropdown.svg');
  top: -5px;
}

.section-table th span:after {
  content: '';
  background-image: url('../../Images/Main/icon-dropdown.svg');
  top: -5px;
}

.room-table tr th span {
  position: relative;
}

.room-table tr th span:after {
  position: absolute;
  content: '' !important;
  background-image: url('../../Images/Main/icon-dropdown.svg');
  top: -5px;
}

.cont-div select {
  width: 384px;
  padding: 4.5px 0 4.5px 11px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  margin-right: 32px;
  font-size: 15px;
}

.kiosk-group-section > div:first-child {
  padding: 0;
}

.cont-div > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.cont-div > div > h3 {
  margin-bottom: 8px;
}

.max-width-full {
  max-width: 100% !important;
}

.max-width-full div > input {
  height: 100%;
}

.max-width-full > div > h3 {
  margin-bottom: 0;
}

.campus-overview .cont-div > div {
  width: 384px;
}

.name-address .cont-div > div {
  max-width: 384px;
}

.note-part textarea::placeholder {
  opacity: 0.5;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.recorderFloors-main {
  width: 640px;
  height: 100vh;
  background-color: #ffffff;
  overflow: scroll;
}

.recorderFloors-header > div {
  padding: 32px;
}

.recorderFloors-header h1 {
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.recorderFloors-header,
.recorderFloors-nav {
  border-bottom: 1px solid #d6e2e4;
  border-top: 1px solid #d6e2e4;
}

.recorderFloors-nav > div {
  padding: 20px 32px;
}

.recorderFloors-nav h2 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.recorderFloors-nav input,
.recorderFloors-div input {
  width: 32px;
  height: 24px;
  margin: 0 6px;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  cursor: pointer;
  background-image: url('../../Images/Main/noun_drag_2422014.svg');
  background-repeat: no-repeat;
  background-position: center center;
}

.recorderFloors-section {
  cursor: move;
}

.table-heading.selectable-wrapper th:first-child span:after {
  content: unset;
}

.rooms-section .floor-room-capacity input,
.rooms-section .floor-room-capacity select {
  width: 100%;
  box-sizing: border-box;
}

.recorderFloors-nav + div > .recorderFloors-section:last-of-type {
  margin-bottom: 65px;
}

.rooms-section .overview-campus .floor-room-capacity {
  max-width: 806px;
  justify-content: space-between;
}

.rooms-section .overview-campus .floor-room-capacity > div {
  padding: 0 !important;
  max-width: 384px !important;
}

.rooms-section .overview-campus .floor-room-capacity div:first-child {
  margin-right: 19px;
}

.rooms-section .overview-campus .floor-room-capacity div:nth-child(2) {
  margin-left: 19px;
}

.overview-audio_video-section {
  background-color: #ffffff;
}

.recorderFloors-section > div {
  padding: 20px 32px;
}

.rooms-section .notes > div {
  max-width: 806px;
}

.rooms-section .notes textarea {
  box-sizing: border-box;
}

.signage-group-assigned .table-heading th span:after {
  content: '' !important;
  background-image: url('../../Images/Main/icon-dropdown.svg');
  top: -5px;
}

.signage-group-assigned {
  padding-bottom: 50px;
}

.recorderFloors-div {
  padding: 16px 0 14px 0;
  border-bottom: 2px solid #f2f2f2;
}

.recorderFloors-div p {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
}

.operatingHours-main {
  width: 100%;
}

.operatingHours-section {
  width: 1280px;
  background-color: #ebeced;
}

.operatingHours-section h2 {
  padding: 16px 0;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.edit-business {
  background-color: #ffffff;
}

.floor-room-capacity select,
.floor-room-capacity input {
  padding: 5px 0 5px 11px !important;
}

.edit-business > .edit-business-div {
  padding: 24px;
  border-bottom: solid 1px #d6e2e4;
  background-color: #ffffff;
}

.operatingHours-table > div {
  padding: 12px 24px 36px 24px;
}

.operatingHours-table table {
  width: 100%;
}

.operatingHours-table tr {
  border-bottom: 2px solid #f2f2f2;
}

.operatingHours-table th {
  padding: 29px 0 20px 0;
  width: 50%;
  text-align: left;
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  color: #404040;
}

.operatingHours-table td {
  padding: 16px 0 18px 0;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.operatingHours-table span {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.edit-floor-name {
  margin-bottom: 13px;
}

.edit-floor-link span {
  width: 64px;
  height: 17px;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  color: #00829b;
}

.header-editOperatingHours > div {
  padding: 32px;
}

.border-bot {
  border-bottom: 2px solid #f2f2f2;
}

.header-editOperatingHours h1 {
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.form-editOperatingHours > div {
  padding: 32px 0 32px 0;
}

.form-editOperatingHours > div:last-of-type {
  margin-bottom: 55px;
}

.form-editOperatingHours h3 {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.12px;
  text-align: left;
  color: #131414;
  margin-bottom: 24px;
}

.form-editOperatingHours h2 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin-bottom: 8px;
}

.form-editOperatingHours p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin-right: 16px;
}

.open-time select {
  width: 230px;
  height: 40px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin-bottom: 32px;
}

.open-time-custom {
  width: 230px;
  height: 40px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin-bottom: 32px;
}

.open-time-custom.custom-hours {
  width: 272px;
}

.css-1okebmr-indicatorSeparator {
  width: unset !important;
  box-shadow: none !important;
}

.css-yk16xz-control:hover {
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
  background: #f2f3f5;
}

.css-1pahdxg-control:hover {
  background: #f2f3f5;
  cursor: pointer;
}

.css-1pahdxg-control {
  border: 1px solid #c5d0d2 !important;
  box-shadow: none !important;
}

.css-1pahdxg-control:hover {
  border: none !important;
  box-shadow: none !important;
}

.all-day input {
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  margin-right: 12px;
}

.day-state h2 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #9d9d9d;
}

.day-state select {
  background-color: #eaeaea;
}

.footer-editOperatingHours > div {
  padding: 32px;
}

.footer-editOperatingHours input:last-of-type {
  padding: 9px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  background-color: #00829b;
  border-radius: 3px;
}

.footer-editOperatingHours input:first-of-type {
  padding: 9px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #131413;
  background-color: #f2f3f5;
  border-radius: 3px;
  margin-right: 16px;
}

.add-existing-room-single-optiom:nth-child(1) {
  margin-bottom: 30px;
  border-bottom: none;
}

.groups-add-for-room {
  width: 100%;
}

.groups-of-room table tr td:last-of-type {
  width: 90%;
}

#rooms {
  margin-top: 40px;
}

.rooms-main {
  max-width: 100%;
  background-color: #ebeced;
  padding: 0 90px;
}

.rooms-main > div {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 30px;
}

.rooms-nav {
  position: relative;
  top: -40px;
  border-bottom: 2px solid #d6e2e4;
}

.rooms-nav img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 3px;
  border: solid 1px #cfd8d9;
  background-color: rgba(0, 0, 0, 0.03);
}

.rooms-nav > div {
  display: flex;
  padding: 12px 0;
}

.rooms-nav > div:not(:last-of-type) {
  margin-right: 24px;
}

.rooms-nav h4 {
  padding: 6px 16px 7px 6px;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 1, 0, 0.8);
}

.room-group-overview .cont-div div:first-child {
  max-width: 384px;
}

.assign-room-group-section h2 {
  padding-top: 0 !important;
}

.assign-room-group-section header {
  margin-top: 0 !important;
}

.room-group-overview .overview-section-footer {
  padding: 0 !important;
}

.rooms-title h1 {
  font-size: 46px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.01px;
  text-align: left;
  color: #131414;
}

.signage-group-table .new-user .align-items-center {
  margin-right: 0 !important;
}

.rooms-title input.back-to {
  border: none;
  padding: 9px 18px 9px 42px;
  cursor: pointer;
  border-radius: 300px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
  background-image: url('../../Images/Main/icon-back.svg');
  background-repeat: no-repeat;
  background-position: 6%;
  outline: none;
  border: unset;
  max-height: 36px;
}

.title-input {
  margin-top: 16px;
}

.rooms-title input.back-to:hover {
  background-color: #d6e2e4;
}

.rooms-title {
  margin-bottom: 48px;
}

.rooms-h2 h2 {
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin: 40px 0 6px 0;
}

.rooms-h2 h2 + span {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
}

.overview-h2 {
  margin: 40px 0 16px;
}

.rooms-section {
  background-color: #ffffff;
  margin-bottom: 24px;
}

.kiosk-campuses {
  padding: 32px 0 !important;
  margin: 0 32px;
  border-top: solid 2px #f2f2f2;
}

.rooms-section form > div:not(.manual-record) {
  padding: 0 24px;
}

.rooms-section form > div > div {
  padding: 32px 0;
}

.rooms-section h3 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding-bottom: 8px;
}

.rooms-section p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.room-release-header {
  display: flex;
  justify-content: space-between;
}

.input-range-custom .focused-indicator {
  transform: rotate(180deg);
}

.kiosks-settings .input-range-custom label.disabled-title {
  color: #9d9d9d;
}

.room-release-footer {
  position: relative;
}

.release-slider {
  display: flex;
  max-width: 577px;
}

.room-release-footer > span {
  position: relative;
  bottom: 30px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fe2c12;
}

.release-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.release-section > div:nth-child(1) {
  padding-left: 10px !important;
}

.kiosks-settings .rooms-section-footer.release-section > div:nth-child(2) {
  padding-right: 0 !important;
}

.release-list {
  margin-top: 32px;
}

.active-block {
  display: flex;
  flex-direction: column;
}

.active-block > div {
  display: flex;
  width: 82px;
}

.active-block > div > label {
  margin-right: 6px;
}

.rooms-section p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.room-release-header {
  display: flex;
  justify-content: space-between;
}

.room-release-footer {
  position: relative;
}

.room-release-footer > span {
  position: relative;
  bottom: 30px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fe2c12;
}

.release-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.release-section > div:nth-child(1) {
  padding-left: 10px !important;
}

.kiosks-settings .rooms-section-footer.release-section > div:nth-child(2) {
  padding-right: 0 !important;
}

.release-list {
  margin-top: 32px;
}

.active-block {
  display: flex;
  flex-direction: column;
}

.active-block > div {
  display: flex;
  width: 82px;
}

.active-block > div > label {
  margin-right: 6px;
}

.publish-changes {
  position: absolute;
  padding: 0;
  left: 65%;
  display: flex;
  top: -162px;
}

.publish-changes div {
  padding: 0 !important;
}

.publish-changes div:first-child {
  margin-right: 16px;
}

.publish-changes div input {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  color: #00829b;
  border-radius: 300px;
  border: solid 2px #eaeaea;
  background-color: #ffffff;
  padding: 9px 18px;
  cursor: pointer;
}

.publish-changes div:nth-child(2) input {
  background-color: #00829b;
  color: #ffffff;
}

.publish-changes div:nth-child(2) input:hover {
  background-color: #025c6d;
}

.bor-bot {
  border-bottom: 1px solid #d6e2e4;
}

.floor-div-workspace {
  margin-right: 94px;
}

.floor-div-kiosk select {
  width: 117px !important;
}

.floor-div-kiosk input {
  width: 251px !important;
}

.floor-div-kiosk > div {
  width: unset !important;
}

.floor-div {
  max-width: 5400px;
}

.floor-div select,
.floor-div input {
  width: 106px;
}

.floor-reorder {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.floor-reorder a {
  display: inline-block;
  margin-left: 16px;
}

.floor-div,
.campus h3 {
  padding-bottom: 8px;
}

.floor-div > div {
  width: 117px;
}

.floor-div > div:not(:last-of-type) {
  margin-right: 16px;
}

.floor-div input,
select {
  /* width: 106px; */
  padding: 5px 0 5px 11px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.wheelchair-div,
.building {
  padding-left: 38px;
}

.suggested-addresses {
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  width: 378px;
  position: absolute;
  top: 70px;
  z-index: 100;
}

.add-map {
  background-image: url('../../Images/UsersTable/btn-search.svg');
  background-repeat: no-repeat;
  background-position: 100% 50%;
  padding-right: 50px !important;
  width: 318px !important;
}

.autocomplete-wrapper {
  position: relative;
}

.autocomplete-wrapper img.reset-search {
  position: absolute;
  top: 2px;
  right: 25px;
  cursor: pointer;
  width: 30px;
}

.flyout-header-v1 .autocomplete-wrapper img.reset-search {
  top: 3px;
  right: 30px;
}

.suggested-addresses li {
  font-size: 18px;
  text-align: left;
  color: #000000;
  cursor: pointer;
  padding: 11px 26px;
}

.suggested-addresses li:hover {
  background: #d6e2e4;
}

.wheelchair-div h3 {
  color: #222222 !important;
}

.wheelchair-div p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin-left: 6px;
}

.wheelchair-div > div:last-of-type {
  margin-top: 13px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  left: 4px;
  bottom: 5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00829b;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.campus select {
  width: 100% !important;
}

.notes h3 + p {
  padding-top: 4px;
}

.notes textarea {
  width: 100%;
  height: 92px;
  padding-left: 11px;
  padding-top: 11px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  font-family: 'proxima-nova' !important;
}

.rooms-section-footer > div {
  padding: 24px 24px !important;
}

.rooms-section-footer > div div:first-of-type input {
  padding: 9px 20px;
  border-radius: 3px;
  border: solid 2px #d6e2e4;
  background-color: #f2f3f5;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin-right: 16px;
  cursor: pointer;
}

.rooms-section-footer > div div:last-of-type input {
  padding: 7px 20px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  border-radius: 3px;
  background-color: #00829b;
  cursor: pointer;
}

/*select style*/

.custom-select {
  width: 205px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  min-width: 140px;
  padding: 8px 16px;
  background-color: #ffffff;
  border-radius: 3px !important;
  border: solid 1px #d6e2e4 !important;
}

/*style the arrow inside the select element:*/

.select-selected:after {
  position: absolute;
  content: '';
  width: 28px;
  height: 32px;
  top: 0;
  right: -2px;
  background-image: url('../../Images/Main/icon-dropdown.svg');
  background-repeat: no-repeat;
  background-position: 100%;
  border: solid 1px #d6e2e4;
  background-color: #f2f3f5;
}

.select-selected.select-arrow-active {
  font-size: 15px;
  color: #00829b;
}

/*point the arrow upwards when the select box is open (active):*/

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
}

/*style the items (options), including the selected item:*/

.select-items div,
.select-selected {
  font-size: 15px;
  color: #00829b;
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
}

.select-items div {
  padding: 14px 11px 13px 35px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}

/*style items (options):*/

.select-items {
  width: 100%;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  background-color: #ffffff;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/

.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.groups-in-room {
  width: 100%;
}

.groups-in-room .admin-menu-panel ul li {
  padding-left: 13px;
}

.rooms-groups {
  background-color: #ffffff;
  margin-bottom: 24px;
}

.rooms-groups > div {
  padding: 0 24px;
}

.rooms-groups > div:first-of-type div {
  padding: 24px 0;
}

.rooms-groups > div:last-of-type div {
  padding: 16px 0;
}

.rooms-groups a {
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
  text-decoration: none;
}

.room-features {
  background-color: #ffffff;
  margin-bottom: 24px;
}

.room-features > div:last-of-type {
  padding: 0 24px;
}

.room-features h3 {
  color: #222222 !important;
  padding-bottom: 12px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  padding-top: 16px;
}

.room-features p:not(.error--message) {
  margin: 0 22px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #131414;
  display: flex;
  align-items: center;
}

.feature-tech-type-card {
  border-bottom: 0 !important;
}

.computer-div {
  flex-wrap: wrap;
  padding: 30px 30px;
}

.computer-div > div {
  border-bottom: 2px solid #d6e2e4;
  width: 45%;
  margin-bottom: 30px;
}

/* 
.computer-div>div>div input:first-of-type {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
} */

.amount input {
  width: 15px;
  border: none;
  text-align: center;
}

.features-amount {
  justify-content: space-between;
}

.features-amount input {
  font-size: 18px;
  color: #131414;
  line-height: 1.33;
  font-stretch: normal;
  font-weight: normal;
}

.features-amount > div {
  width: 48% !important;
  border-bottom: 1px solid #d6e2e4;
  margin-bottom: 0px;
}

.computer-div > div > div input[type='button']:first-of-type {
  border: solid 2px #eaeaea;
  background-color: #f4f4f4;
}

.computer-div > div > div input[type='button'] {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid 2px #eaeaea;
  background-color: #ffffff;
  cursor: pointer;
}

.pad16 {
  padding-bottom: 16px;
}

.devives-paired {
  background-color: #ffffff;
}

.cancell-pairing {
  margin-right: 16px;
}

.remove-assigned-room {
  margin-top: 16px;
}

.devives-paired > div {
  padding: 0 24px 32px 24px;
}

.devives-paired > div > div div:first-of-type {
  width: 300px;
}

.devives-paired select {
  border: none;
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: left;
  color: #404040;
  cursor: pointer;
  padding: 0;
}

.devives-paired h6 {
  padding-top: 2px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.devives-paired a {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
}

.devives-paired p {
  margin: 6px 0 16px 0;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.devives-paired .select-div {
  padding: 29px 0 20px 0;
}

.pad-top16 {
  padding-top: 16px;
}

.organizationSettings-main {
  width: 80%;
  margin: 0 10%;
}

.sub-title-settings{
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  text-align: left;
  margin-bottom: 32px;
}

.organizationSettings-div {
  padding-bottom: 16px;
}

.organizationSettings-div h1 {
  font-size: 46px;
  font-weight: 600;
  padding: 16px 0;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.01px;
  text-align: left;
  color: #131414;
}

.organizationOverview-div h2 {
  padding: 16px 0;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding-top: 0;
}

.orgSettingsNavMenu {
  display: flex;
  border-bottom: solid 1px #cdd9db;
  margin-bottom: 40px;
}

.orgSettingsNavMenu > span,
.orgSettingsNavMenu > div > span {
  padding: 9px 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

.orgSettingsNavMenu > div {
  display: flex;
}

.orgSettingsNavMenu > div > div {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  min-width: 162px;
  position: absolute;
  margin-top: 38px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
}

.orgSettingsNavMenu > div > span::after {
  content: '';
  background-image: url('../../Images/Main/icon-dropdown.svg');
  background-repeat: no-repeat;
  background-position: 100%;
  display: inline-block;
  width: 26px;
  height: 11px;
  transform: rotate(0deg);
  transition: transform 0.5s;
}

.orgSettingsNavMenu > div[data-clicked='true'] {
  background-color: #d9e0e6;
}

.orgSettingsNavMenu > div[data-clicked='true'] > span::after {
  transform: rotate(180deg) translateX(-3px);
  transition: transform 0.5s;
}

.orgSettingsNavMenu > div > div > span {
  padding-top: 14px;
  padding-bottom: 13px;
  padding-left: 11px;
  cursor: pointer;
}

.orgSettingsNavMenu > div > div > span:hover {
  background-color: #ecf0f3;
}

.orgSettingsNavMenu > div > div > span:first-child {
  margin-top: 12px;
}

.orgSettingsNavMenu > div > div > span:last-child {
  margin-bottom: 12px;
}

.orgSettingsNavMenu > div > div > span {
  font-size: 18px;
}

.orgSettingsNavMenu > span:hover,
.orgSettingsNavMenu > div:hover > span {
  background-color: #d9e0e6;
}

.orgSettingsNavbarActiveSpan {
  border-bottom: solid 4px #00829b;
}

.orgSettings-products-subsection-header {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 16px;
}

.orgSettings-products-subsection-header h2 {
  font-size: 28px;
  font-weight: 600;
}

.orgSettings-products-subsection-header p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
}

.service-account-settings {
  padding-top: 26px !important;
  padding-bottom: 15px !important;
  border-bottom: solid 2px #f2f2f2;
}

.service-account-settings h3 {
  margin-top: -19px;
}

.organizationOverview-section,
.integrations-section,
.accountSettings-section,
.domainRestrictions-section {
  background-color: #ffffff;
  margin-bottom: 24px;
}

.white-card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  margin-bottom: 40px;
}

.basic-white-card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  margin-bottom: 40px;
  padding-left: 24px;
  padding-right: 29px;
}

.white-card-input-elements {
  padding: 32px 24px 31.5px;
  border-bottom: 1px solid #d6e2e4;
}

.white-card-input-elements-content {
  display: flex;
  gap: 62px;
}

.white-card-input-elements-content > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 360px;
  width: 100%;
}

.white-card-footer {
  padding: 24px;
}

.white-card-inner-element {
  display: flex;
  justify-content: space-between;
}

.white-card-inner-element > div {
  display: flex;
  gap: 7px;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 36px;
}

.white-card-inner-element + .white-card-inner-element {
  border-top: solid 2px #f2f2f2;
}

.white-card-temp-range > div {
  flex-direction: row;
  width: 100%;
}

.white-card-temp-range > div:first-child > div:first-child > div:first-child > h3 {
  padding-bottom: 28px;
}

.white-card-temp-range .input-range-custom {
  margin-top: 28px;
  gap: 12.2px;
}

.white-card-temp-range .dash {
  margin-top: 39px;
}

.white-card-inner-element > div > p {
  font-size: 20px;
  font-weight: 300;
  white-space: pre-line;
}

.white-card-inner-element > div > pre {
  font-size: 15px;
  line-height: 1.2;
}

.white-card-mid-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 125.5px;
  background-color: #fbfbfb;
  border-top: 1px solid #d6e2e4;
  margin-left: -24px;
  margin-right: -29px;
  padding: 0 22px;
}

.white-card-inner-link-element {
  padding-top: 33px;
  padding-bottom: 34px;
  padding-left: 35px;
  padding-right: 24px !important;
  border-top: solid 1px #d6e2e4;
  margin-left: -24px;
  margin-right: -29px;
}

.white-card-inner-link-element > div:not(.justify-content-flex-end) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.white-card-inner-link-element > a > span,
.white-card-inner-link-element > div > a > span {
  font-size: 15px;
  font-weight: 600;
  color: #00829b;
  cursor: pointer;
}

.white-card-inner-element textarea {
  padding: 11px 9px 13px 11px;
  min-width: 806px;
  box-sizing: border-box;
  height: 93px;
  border: solid 2px #eaeaea;
  margin-bottom: -4px;
}

.white-card-inner-element textarea::placeholder {
  white-space: pre-line;
  font-family: 'proxima-nova' !important;
}

.white-card-inner-element + .full-line-white-card {
  margin-left: -24px;
  margin-right: -29px;
  padding-right: 24px;
  padding-left: 24px;
}

.white-card-link-and-btn_v2 {
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 32px;
}

.basic-white-card > form > .occupancy-restrictions-campuses-flyout-header {
  margin-top: 0;
  border-top: 0;
}

.occupancy-section_text-container {
  background-color: #fbfbfb;
}

.white-card-with-no-padding {
  padding-left: 0;
  padding-right: 0;
}

.white-card-with-no-padding .white-card-inner-link-element {
  margin: 0;
}

.white-card-with-no-padding > form > :is(div:nth-child(1), div:nth-child(2)) {
  padding-right: 24px;
  padding-left: 24px;
}

.white-card-with-no-padding > form > div:first-child {
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #d6e2e4;
}

.white-card-with-no-padding > form > div:first-child > p {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: 0.01px;
}

.white-card-with-no-padding > form > div:first-child > p > strong {
  color: #00829b;
}

.white-card-with-no-padding > form > div:nth-child(2) {
  display: flex;
  gap: 56px;
  padding-top: 31.5px;
  padding-bottom: 31.5px;
}

.white-card-with-no-padding > form > div:nth-child(2) > div {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 360px;
}

.white-card-with-no-padding > form > div:nth-child(2) > div > input {
  width: 100%;
  max-width: 360px;
  height: 32px;
  max-height: 32px;
  box-sizing: border-box;
  padding: 7px 0 8px 11px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
}

.askIcon-occupancy-section {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  zoom: 1;
  position: relative;
  border: 1px solid #d6e2e4;
  border-radius: 50%;
}

.askIcon-occupancy-section:hover {
  background-color: #e3f0fe;
}

.askIcon-occupancy-pressed {
  border: 1px solid #00829b;
}

.loader--start-position {
  justify-content: flex-start;
  align-items: flex-start;
}

.occupancy-settings-icon-container {
  display: flex;
  align-items: center;
  gap: 6px;
}

.white-card-with-no-padding > form > div:nth-child(2) > div > pre {
  font-size: 15px;
  color: #fe2c12;
}

.white-card-with-no-padding > form > div:nth-child(2) > div > input::-webkit-outer-spin-button,
.white-card-with-no-padding > form > div:nth-child(2) > div > input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.error-input-border-color {
  border: solid 2px #e1260f !important;
}

.occupancy-explanation-container {
  margin-top: 32px;
}

.occupancy-explanation-container span {
  display: block;
  padding-top: 32px;
}

.occupancy-explanation-container ul {
  list-style: unset;
  padding-left: 29px;
  padding-top: 10px;
}

.explaination-images-container {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.explaination-images-container > div > img {
  width: 100%;
}

.explaination-images-container > div > pre {
  font-size: 15px;
  color: #727272;
  padding-top: 10px;
}

.expaination-flyout-footer {
  padding-bottom: 107px;
}

.expaination-flyout-footer ~ .flyout-footer-wrapper {
  background-color: #ffffff;
  position: fixed;
}

.card-merged-orgSettings {
  margin-bottom: 0 !important;
}

.card-merged-orgSettings + .integrations-section > div {
  padding-left: 0;
  padding-right: 0;
  border-top: solid 2px #f2f2f2;
}

.card-merged-orgSettings + .integrations-section {
  padding-left: 24px;
  padding-right: 24px;
}

.flyout-description p {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  white-space: pre-line;
}

.switcher-label-span {
  font-size: 18px;
  font-weight: normal;
  margin-left: 6px;
}

.border-top {
  border-top: solid 2px #f2f2f2;
}

.questionary-section {
  margin-top: 48px;
  margin-bottom: 0;
}

.sub-title-questionaire {
  font-size: 20px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222 !important;
}

.domainRestrictions-section.questionary-section > div:last-of-type {
  padding-bottom: 0;
  padding-top: 0;
}

.domainRestrictions-section.questionary-section > div:first-of-type {
  padding-bottom: 0;
}

.domainRestrictions-section.questionary-section-footer > div:last-of-type {
  padding: 0 0 0 14px;
}

main
  section:not(.welcome-get):not(.device-subs):last-of-type.domainRestrictions-section.questionary-section-footer {
  margin-bottom: 0;
}

.questionary-section .questionary-section-border p {
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: left;
  color: #404040;
}

.questionary-section > div:last-of-type div.single-domain {
  padding-left: 0;
}

.integrations-section.first-type {
  margin-bottom: 0;
}

.integrations-section .button--cancel {
  margin-right: 0;
}

.organizationOverview-section h4 {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
  margin-top: 24px;
}

.ip-range-calc {
  margin-bottom: 80px;
}

.ip-range-calc > div {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 70%;
}

.ip-range-calc > div .range span {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.ip-range-calc > div p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.owership-info p {
  margin-bottom: 32px;
}

.check-deletion {
  margin-top: 10px;
}

.check-deletion span {
  font-weight: bold;
}

.organizationOverview-section > form > div > div {
  padding: 32px 24px;
}

.organizationOverview-section h3 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin-bottom: 8px;
}

.contoso {
  width: 384px;
  height: 26px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding-left: 11px;
}

.flyout-header-v1 h1 ~ div.ownership-info {
  padding-top: 0;
}

.flyout-header-v1 h1 ~ div.tech-stack-input {
  margin-bottom: 0;
  padding-bottom: 24px;
}

.ownership-info p {
  margin-bottom: 26px;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.feat-name {
  margin-bottom: 0;
  padding-bottom: 24px;
  border-bottom: none;
}

.feat-name + p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.single-domain {
  cursor: pointer;
}

.single-domain > p {
  text-decoration: underline;
}

.single-domain:hover {
  background-color: #eaeaea;
}

.questionary-section .single-domain:hover {
  background-color: #fbfbfb;
}

.questionary-section > div > a:nth-child(1) .single-domain {
  border-bottom: 2px solid #f2f2f2;
}

.questionary-section > div > a:nth-child(2) .single-domain {
  margin-bottom: 31px;
}

.questionary-section-border {
  border-bottom: 2px solid #f2f2f2;
}

.border-bot1 {
  border-bottom: solid 1px #d6e2e4;
}

.logo-div {
  width: 283px;
  height: 200px;
  margin-bottom: 24px;
  border: 1px solid #f2f2f2;
}

.logo-div img {
  width: 100%;
  position: relative;
  /* top: 75px; */
}

.logo-div-slide {
  width: 320px;
  height: 230px;
}

.logo-div-slide img {
  width: 100% !important;
  height: 100%;
}

.validation-rules {
  list-style: disc;
  margin-bottom: 10px;
}

.validation-rules-heading {
  text-align: left;
  width: 100%;
}

.validation-rules li {
  margin-bottom: 10px;
}

.file-custom {
  position: absolute;
  cursor: pointer;
  width: 378px;
  top: -2px;
  right: 0;
  left: -2px;
  z-index: 5;
  padding: 7px 11px 8px 11px;
  background-color: #fff;
  border: solid 2px #eaeaea;
  border-radius: 3px;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.file-custom:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: 'Choose Image';
  padding: 7px 11px 8px 11px;
  background-color: #f2f3f5;
  border: solid 2px #eaeaea;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.file-custom:after {
  content: 'Choose image (.jpg or .png)';
}

.file-custom-csv::before {
  content: 'Choose File' !important;
}

.file-custom-csv::after {
  content: 'Choose .csv' !important;
}

.import-type .file-uploader {
  position: absolute;
  top: 0;
  width: 138px;
}

.in-detail-import {
  border-bottom: none !important;
}

.in-detail-import p:not(:last-child) {
  margin-bottom: 8px;
}

.in-detail-import .csv-download-ink {
  padding-top: 0;
  padding-bottom: 0;
}

.csv-download-ink.custom-kisok-download {
  padding-top: 0;
  padding-bottom: 0;
}

.in-detail-import .success-import .warning-content {
  border-top: 4px solid #009b05;
}

.import-instructions .csv-download-ink {
  padding-top: 0;
  padding-bottom: 0;
}

.custom-error-import {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fe2c12;
}

.connect-account-header + div.padd32 .office-conf-browse {
  color: #131414;
  padding-bottom: 8px;
}

.organizationOverview-section-footer > div {
  padding: 24px 24px !important;
  height: 36px;
}

.organizationOverview-section-footer > div button,
.overview-section-footer button,
.overview-section-footer > div .rooms-section-footer > div,
.rooms-section-footer button,
.rooms-section-footer input {
  height: 32px;
}

.organizationOverview-section-footer > div div:last-of-type input {
  margin-left: 16px;
}

.h2 {
  padding: 16px 0;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.padd32 h3 {
  margin-bottom: 12px;
}

.building-overview .overview-campus .cont-div {
  padding: 32px 24px !important;
}

.building-overview .overview-note {
  padding: 32px 24px !important;
}

.building-overview .note-part {
  padding: 32px 24px !important;
}

.integrations-section > div,
.accountSettings-section > div {
  padding: 24px;
}

.integrations-section h3,
.accountSettings-section h3 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
  margin-bottom: 6px;
}

.integrations-section p,
.accountSettings-section p {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.integrations-section input {
  font-size: 16px;
}

.border-bot2 {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 16px;
}

.accountSettings-section input,
.domainRestrictions-section input {
  padding: 6px 11px 6px 32px;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  cursor: pointer;
}

/* .transfer input {
  background-image: url('../../Images/Main/icon-transfer.png');
  background-repeat: no-repeat;
  background-position: left center;
} */

.transfer-block {
  position: relative;
}

.transfer-block img {
  position: absolute;
  width: 30px;
  height: 28px;
  padding-top: 2px;
}

.transfer input:hover {
  background-color: #f2f3f5;
}

.delete-account {
  padding-top: 12px;
}

.delete-account input {
  background-image: url('../../Images/Main/icon-delete.png');
  background-repeat: no-repeat;
  background-position: left center;
}

.domainRestrictions-div h2 {
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding: 16px 0 6px 0;
}

.domainRestrictions-div h4 {
  padding-bottom: 16px;
  padding-top: 0;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.domainRestrictions-section > div:first-of-type {
  padding: 24px;
}

.domainRestrictions-section > div:last-of-type {
  padding: 12px 24px 32px 24px;
}

.domainRestrictions-section input {
  background-image: url('../../Images/Main/icon-add.svg');
  background-repeat: no-repeat;
  background-position: left center;
}

.domainRestrictions-section input:hover {
  background-color: #f2f3f5;
}

.domainRestrictions-section > div:last-of-type div {
  padding: 16px 16px;
}

.domainRestrictions-section a {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
  text-decoration: none;
}

/* Devices */

.pairDevice {
  background-color: #ffffff;
}

.padd32 {
  padding: 32px;
}

.paddTB32 {
  padding: 32px 0;
}

.paddTRL32 {
  padding: 32px 32px 0 32px !important;
}

.flyout-content-bottom-up {
  padding-bottom: 130px;
}

.set-devicinfo {
  padding: 0 32px;
}

.pin-info {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.pairDevice p {
  padding: 24px 32px;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.pairDevice-header h2 {
  font-size: 28px;
  font-weight: 600;
  color: #131414;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.pairDevice-header h3 {
  font-size: 20px;
  font-weight: 600;
  color: #222222;
}

.pair-room-select {
  margin-top: 30px;
}

.pairDevice-header span {
  margin-left: 12px;
}

.black-div {
  background-color: #27272b;
}

.black-div h2 {
  font-size: 46px;
  font-weight: 600;
  color: #f3f3df;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.01px;
}

.black-div h3 {
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.12px;
  color: #f3f3df;
  text-transform: uppercase;
  margin-top: 6px;
}

.devicecol1 {
  width: 0;
  height: 4px;
  background-color: #00829b;
  transition: width 60s ease-in;
}

.devicecol2 {
  width: 100%;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.2);
}

.pairDevice-footer {
  border-top: 2px solid #f2f2f2;
}

.device-butt-grey {
  padding: 9px 20px;
  border-radius: 3px;
  border: solid 2px #d6e2e4;
  background-color: #f2f3f5;
  font-size: 13px;
  font-weight: 600;
  color: #131414;
  cursor: pointer;
}

.device-butt-blue {
  padding: 9px 20px;
  border-radius: 3px;
  background-color: #00829b;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.addLicensing-main {
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.06);
  background-color: #ffffff;
}

.addLicensing-main form > div:not(:last-child) {
  padding: 32px;
}

.addLicensing-main h1 {
  font-size: 28px;
  font-weight: 600;
  color: #131414;
  line-height: 1.43;
}

.addLicensing-main h2,
.visibility h2 {
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  line-height: 1.3;
}

.visibility h2 {
  margin-bottom: 13px;
}

.addLicensing-main h3 {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.01px;
  color: #000000;
  padding: 7px 0 12px 0;
}

.addLicensing-main p {
  font-size: 15px;
  font-weight: normal;
  color: #131414;
  padding-right: 163px;
}

.addLicensing-main p.license-error {
  color: #e1260f;
  font-size: 15px;
  text-align: left;
}

.addLicensing-main span {
  color: #e1260f;
}

.bord-bott {
  border-bottom: 2px solid #f2f2f2;
}

.grey-input {
  padding: 9px 20px;
  border-radius: 3px;
  border: solid 2px #d6e2e4;
  background-color: #f2f3f5;
  font-size: 13px;
  font-weight: 600;
  color: #131414;
  cursor: pointer;
}

.blue-input {
  padding: 9px 20px;
  border-radius: 3px;
  background-color: #00829b;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  margin-left: 16px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: solid 2px #9d9d9d;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 10px;
  width: 10px;
  left: 2px;
  bottom: 3px;
  background-color: #9d9d9d;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #00829b;
  border: 2px solid #00829b;
}

input:checked + .slider:before {
  background-color: #ffffff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider:not(.weel-slider)::after {
  content: 'On';
  position: absolute;
  left: 46px;
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  color: #131414;
}

.custom-on + span::after {
  content: 'On';
}

.custom-off + span::after {
  content: 'Off' !important;
}

.custom-on-yes + span::after {
  content: 'Yes' !important;
}

.custom-off-yes + span::after {
  content: 'No' !important;
}

.off {
  font-size: 18px;
  font-weight: normal;
  color: #9d9d9d;
  position: absolute;
  left: 48px;
  top: 2px;
  background-color: #ffffff;
}

input:checked ~ .off {
  display: none;
}

.onoff {
  margin-right: 28px;
}

/* Device detail */

.input-style {
  padding: 6px 11px 6px 11px;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  color: #00829b;
  cursor: pointer;
}

.input-style:hover {
  background-color: #f2f3f5;
}

.surfaceGo-main {
  width: 975px;
  margin: 0 auto;
  background-color: #ebeced;
}

.surfaceGo-title {
  padding-top: 24px;
}

.surfaceGo-title h1 {
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 0.01px;
  color: #131414;
  padding: 16px 0;
}

.surfaceGo-title input {
  padding: 9px 18px 9px 42px;
  cursor: pointer;
  border-radius: 300px;
  background-color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #00829b;
  background-image: url('../../Images/Main/icon-back.svg');
  background-repeat: no-repeat;
  background-position: 6%;
  margin-bottom: 32px;
}

.overview-section,
.roomAssigned,
.productLicensing,
.deviceInformation,
.supportLogs {
  background-color: #ffffff;
  margin-bottom: 24px;
}

.overview-section h4 {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.01px;
  color: #000000;
  margin-top: 24px;
}

.overview-section > div > div {
  padding: 32px 24px;
}

.overview-section h3 {
  font-size: 20px;
  font-weight: 600;
  color: #131414;
  margin-bottom: 8px;
}

.overview-section p {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.01px;
  color: #000000;
}

.online {
  width: 12px;
  height: 12px;
  background-color: #009b05;
  border-radius: 50%;
  margin-right: 4px;
}

.offline {
  width: 12px;
  height: 12px;
  background-color: #ebeced;
  border-radius: 50%;
  margin-right: 4px;
}

.overviewName > div > div:nth-child(2) {
  width: 365px;
}

.overviewName p {
  padding-top: unset !important;
}

.nameinput {
  width: 75%;
  height: 28px;
  padding: 0 11px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  color: #131414;
}

.overview-section textarea {
  max-width: 772px;
  height: 67px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: #ffffff;
  padding: 11px;
}

.overview-section textarea::placeholder {
  opacity: 0.5;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.border-bot1 {
  border-bottom: solid 1px #d6e2e4;
}

.border-bot2 {
  border-bottom: solid 2px #eaeaea;
}

.overview-section-footer > div {
  padding: 24px 24px !important;
}

.overview-section-footer > div div:first-of-type input {
  padding: 9px 20px;
  border-radius: 3px;
  border: solid 2px #d6e2e4;
  background-color: #f2f3f5;
  font-size: 16px;
  font-weight: 600;
  color: #9d9d9d;
  margin-right: 16px;
  cursor: pointer;
}

.overview-section-footer > div div:last-of-type input {
  padding: 9px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

.roomAssigned table th {
  text-align: left;
}

.roomAssigned > div > div:first-of-type {
  padding: 24px;
}

.roomAssigned > div > div:last-of-type {
  padding: 21px 24px 32px 24px;
}

.surfaceGo-main table {
  width: 100%;
}

.surfaceGo-main table th {
  font-size: 13.5px;
  font-weight: bold;
  letter-spacing: 0.14px;
  color: #404040;
  padding: 20px 0;
}

.surfaceGo-main table a {
  font-size: 20px;
  font-weight: 600;
  color: #00829b;
}

.surfaceGo-main table h5 {
  font-size: 15px;
  font-weight: normal;
  color: #131414;
  padding-top: 6px;
}

.surfaceGo-main table p {
  font-size: 15px;
  font-weight: normal;
  color: #727272;
  padding-top: 6px;
}

.surfaceGo-main table tbody td {
  padding: 16px 0;
}

.surfaceGo-main table span {
  font-size: 18px;
  font-weight: normal;
  color: #131414;
}

.tr-active {
  border-left: 4px solid #009b05;
}

.tr-no-active {
  border-left: 4px solid #ebeced;
}

/* .productLicensing>div>div:first-of-type {
      padding: 24px;
}

.productLicensing>div>div:last-of-type {
      padding: 21px 24px 32px 24px
} */

.productLicensing table thead > tr > th:first-of-type {
  padding-left: 28px;
}

.productLicensing table tbody > tr > td:first-of-type {
  padding-left: 24px;
}

.deviceInformation > div {
  padding: 0 24px 24px 24px;
}

.deviceInformation > div > div > div {
  width: 30%;
}

.deviceInformation h3 {
  padding: 32px 0 12px 0;
  font-size: 20px;
  font-weight: 600;
  color: #131414;
}

.deviceInformation p {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.01px;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.supportLogs > div > div {
  padding: 16px 24px 24px 24px;
}

.supportLogs > div > div:first-of-type {
  padding: 24px;
}

.supportLogs > div > div:last-of-type {
  padding: 24px;
}

.supportLogs p {
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  padding: 16px 0;
}

.csv-download-ink {
  padding: 9px 20px;
  border-radius: 3px;
  background-color: #fff;
  font-size: 20px;
  font-weight: 600;
  color: #00829b;
  cursor: pointer;
  text-decoration: underline;
  padding-right: 0;
  padding-left: 0;
}

.welcome {
  width: 100%;
  position: relative;
  top: -39px;
}

.welcome-section {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  position: relative;
  top: -1px;
  display: flex;
  border-bottom: solid 1px #f2f3f4;
}

.welcome-section > div {
  margin: 0 auto;
  width: 80%;
  max-width: 1280px;
}

.welcome-section h2 {
  font-size: 46px;
  line-height: 1.22;
  font-weight: 600;
  letter-spacing: 0.01px;
  color: #131414;
  padding: 40px 0 6px;
}

.welcome-section h3 {
  font-size: 28px;
  font-weight: 600;
  color: #131414;
  line-height: 1.43;
}

.welcome-section h4 {
  font-size: 20px;
  font-weight: 600;
  color: #131414;
  line-height: 1.3;
}

.welcome-section h5 {
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.01px;
  color: #131414;
  padding-bottom: 40px;
  line-height: 1.33;
}

.welcome-section p {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.01px;
  color: #000000;
  line-height: 1.3;
}

.welcom-bordBott {
  border-bottom: 1px solid #d6e2e4;
}

.deviceStatus h3 {
  padding: 40px 0 16px 0;
}

.deviceHealthy h4 {
  padding: 36px 24px 16px 24px;
}

.healthy-device-block {
  display: flex;
  margin-bottom: 64px;
  justify-content: flex-start;
}

.deviceHealthy {
  border: 1px solid #d6e2e4;
  border-top: 4px solid #009b05;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  flex: 1 0 20%;
  max-width: 414px;
}

.deviceHealthy:not(:first-child) {
  margin-left: 16px;
}

.deviceHealthy p {
  padding: 0 24px 32px 24px;
}

.deviceHealthy button {
  padding: 9px 20px;
  border-radius: 3px;
  background-color: #00829b;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: normal;
  color: #ffffff;
}

.deviceHealthy span {
  font-weight: bold;
  color: #00829b;
}

.padd24 {
  padding: 24px;
}

.exploreTool {
  padding: 48px 0 64px 0;
}

.four-div {
  padding-top: 48px;
}

.four-div > div {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.four-div > div:not(:last-child) {
  margin-right: 40px;
}

.four-div p {
  padding-top: 12px;
  height: 120px;
}

.four-div a {
  font-size: 20px;
  font-weight: 600;
  color: #00829b;
}

.welcome-get {
  background: rgb(242, 243, 244);
  background: -moz-linear-gradient(
    180deg,
    rgba(242, 243, 244, 1) 0%,
    rgba(235, 239, 242, 1) 100%,
    rgba(166, 199, 221, 1) 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(242, 243, 244, 1) 0%,
    rgba(235, 239, 242, 1) 100%,
    rgba(166, 199, 221, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(242, 243, 244, 1) 0%,
    rgba(235, 239, 242, 1) 100%,
    rgba(166, 199, 221, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#f2f3f4", endColorstr="#a6c7dd", GradientType=1);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
}

.welcome-get > div {
  margin: 0 auto;
  width: 80%;
  max-width: 1280px;
}

.welcome-get h3 {
  font-size: 28px;
  font-weight: 600;
  color: #131414;
  line-height: 1.43;
}

.welcome-get p {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.01px;
  color: #000000;
  line-height: 1.3;
}

.welcome-get a {
  font-size: 20px;
  font-weight: 600;
  color: #00829b;
  line-height: 1.3;
  text-decoration: underline;
}

.get-div > div:first-of-type,
.my-div > div:first-of-type {
  padding: 40px 0 16px 0;
}

.get-div h3 {
  margin-bottom: 6px;
}

/*.get-div button {*/

/*      padding: 6px 11px 6px 32px;*/

/*      border-radius: 3px;*/

/*      border: solid 1px #d6e2e4;*/

/*      background-color: #ffffff;*/

/*      font-size: 15px;*/

/*      font-weight: 600;*/

/*      color: #00829b;*/

/*      cursor: pointer;*/

/*      background-image: url("../../Images/Main/icon-hide.svg");*/

/*      background-repeat: no-repeat;*/

/*      background-position: left;*/

/*}*/

.get-div button:hover {
  background-color: #f2f3f5;
}

.getAccount {
  width: 100%;
  display: flex;
  height: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.getAccount > div {
  padding: 64px 24px 48px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 16px;
  height: 190px;
  max-width: 368px;
  /* width: 21.4%; */
  flex: 1 0 23%;
}

/* .getAccount>div:not(:nth-child(3n)) {
      margin-right: 16px;
} */

.getAccount h3,
.getAccount a {
  color: #ffffff;
}

.getAccount p {
  padding-top: 9px;
  color: #ffffff;
}

.myAccount {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 188px;
  grid-gap: 16px;
}

.myAccount > div {
  padding: 64px 24px 48px 24px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #d6e2e4;
  background-color: #ffffff;
  text-align: center;
  align-items: center;
  /* justify-content: ceter; */
}

.welcome-footer {
  background-color: transparent;
  position: relative;
  top: 43px;
  width: 100%;
}

.welcome-footer img {
  width: 100% !important;
}

/* Sub details */

.communifyMeet-main {
  max-width: 100%;
  background-color: #ebeced;
  padding: 0 16px;
}

.communifyMeet-main > div {
  max-width: 1280px;
  margin: 0 auto;
}

.communifyMeet-title h1 {
  padding: 16px 0;
  font-size: 46px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.01px;
  text-align: left;
  color: #131414;
}

.communifyMeet div:first-of-type {
  padding-top: 24px;
}

.sub-detail.overview-section {
  margin-bottom: 24px;
  background: transparent;
}

.sub-detail.overview-section > div > div:not(.sub-border) {
  width: 416px;
  height: 228px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.sub-detail.overview-section > div > div:first-of-type {
  margin-right: 16px;
}

.sub-detail.overview-section h4 {
  font-size: 38px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.01px;
  text-align: center;
  color: #131414;
  margin-bottom: 9px;
  margin-top: 0;
  padding-top: 0;
}

.sub-detail.overview-section h5 {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: center;
  color: #000000;
}

.sub-detail.overview-section p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: center;
  color: #727272;
}

.sub-detail.overview-section .overiew-border {
  width: 100%;
  height: 3px;
  background-color: #f2f2f2;
  bottom: 5px;
}

.communifyMeet-title input {
  padding: 9px 18px 9px 42px;
  cursor: pointer;
  border-radius: 300px;
  background-color: #ffffff;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
  background-image: url('../../Images/Main/icon-back.svg');
  background-repeat: no-repeat;
  background-position: 5%;
  margin-bottom: 32px;
}

.communifyMeet-h2 h2 {
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding: 16px 0;
}

.subscription-section {
  background-color: #ffffff;
  margin-bottom: 24px;
}

.subscription-section > div {
  padding: 0 24px 14px 24px;
}

.subscription-section table th {
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: left;
  color: #404040;
  padding: 29px 0 20px 0;
}

.subs-table tr td:nth-child(1) {
  border-left: 4px solid #009b05;
  padding-left: 15px;
}

.subs-info {
  margin-bottom: 40px;
}

.subs-info.kiosk-sub-info > div {
  border-top: 4px solid #d6e2e4;
}

.subs-info > div {
  padding: 36px 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #d6e2e4;
  background-color: #fbfbfb;
  width: 45%;
}

.subs-info h3 {
  font-size: 20px;
  font-weight: 600;
  color: #131414;
  letter-spacing: normal;
  margin-bottom: 16px;
}

.subs-info p {
  text-align: left;
  font-size: 19px;
  line-height: 1.3;
  font-weight: 300;
  color: #000000;
  letter-spacing: normal;
  margin-bottom: 56px;
}

.subs-info a {
  font-size: 20px;
  font-weight: 600;
  color: #00829b;
}

.subscription-section table tr:first-of-type td {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  padding: 16px 0 6px 0;
}

.subscription-section table tr:first-of-type td span {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
  padding-bottom: 16px;
}

.td-style {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: red;
}

.deficesAssigned-section {
  background-color: #ffffff;
  margin-bottom: 24px;
}

.tableLeft-border {
  width: 4px;
  height: 81px;
  top: 67px;
  left: 24px;
  background-color: #2d464b;
  position: absolute;
}

.deficesAssigned-section > div > div:first-of-type {
  padding: 24px 24px;
  border-bottom: 2px solid #d6e2e4;
}

.deficesAssigned-section > div > div:last-of-type {
  padding: 12px 24px 24px 24px;
}

.deficesAssigned-section a {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
  text-decoration: none;
}

.deficesAssigned-section > div:first-of-type input:first-of-type {
  width: 360px;
  height: 32px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  background-image: url(../../Images/UsersTable/btn-search.svg);
  background-repeat: no-repeat;
  background-position: 100%;
}

.deficesAssigned-section > div:first-of-type input:last-of-type {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  border: solid 1px #d6e2e4;
  background-color: rgba(255, 255, 255, 0.4);
}

.deficesAssigned-section p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
  padding: 16px 0;
}

.deficesAssigned-section a {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
  text-decoration: underline;
}

.deficesAssigned-section table th {
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: left;
  color: #404040;
  padding: 29px 0 20px 0;
}

/* .deficesAssigned-section table tr th:nth-of-type(4n) {
      width: 30%;
  } */

.bord-bot {
  border-bottom: 2px solid #f2f2f2;
}

/* .previous-next {
      width: 100%;
      padding: 40px 0 64px 0;
} */

.previous-next input {
  padding: 9px 16px;
  border: solid 1px #c5d0d2;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #00829b;
}

.previous-next ul {
  flex: 1;
  justify-content: center;
}

.row-count-show {
  border-radius: 3px;
  border: none;
  background-color: #f2f3f5;
  box-sizing: unset !important;
  box-shadow: none;
  cursor: pointer;
  width: 179px;
  height: 32px;
  margin: 40px 0 0 auto;
}

.previous-next .page {
  color: #131414;
}

.previous-next .page:hover {
  background-color: #00829b;
}

/* Slides overview */

.slide-overview .slide-input {
  width: 50%;
}

.slide-overview textarea.slide-input {
  height: 71px;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: #ffffff;
  padding: 10px;
}

.slide-overview .background-wrapper {
  background: #fbfbfb;
}

.slide-publish-btn:disabled:hover {
  background-color: #00829b !important;
}

/* Signage Settings */

.meetingRoom {
  width: 1280px;
  margin: 0 auto;
}

.meetingRoom h3 {
  font-size: 20px;
  font-weight: 600;
  color: #131414;
}

.meetingRoom p {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.01px;
  color: #000000;
}

.meetingRoom-section {
  margin: 24px 0 32px 0;
}

.meetingRoom-section h1 {
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 0.01px;
  color: #131414;
  padding: 16px 0 6px 0;
}

.meetingRoom-section h2 {
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.01px;
  color: #131414;
  padding-bottom: 16px;
}

.meetingRoom-section > div input:first-of-type {
  padding: 9px 18px;
  border-radius: 300px;
  border: solid 2px #eaeaea;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  color: #00829b;
  cursor: pointer;
  margin-right: 16px;
  outline: none;
}

.meetingRoom-section > div input:last-of-type {
  padding: 9px 18px;
  border-radius: 300px;
  border: solid 2px #00829b;
  background-color: #00829b;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}

.meetingRoom-section > div .submit-overview:not(:disabled):hover {
  background-color: #025c6d;
}

.h2 {
  font-size: 28px;
  font-weight: 600;
  color: #131414;
  padding: 16px 0;
}

.sectionStyle {
  background-color: #ffffff;
  margin-bottom: 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
}

.overviewSettings h3 {
  margin-bottom: 8px;
}

.overviewSettings > div:first-of-type {
  padding: 32px 24px;
}

.logo-div > div:last-of-type,
.bg-div {
  margin-left: 32px;
}

.overviewSettings img {
  padding: 38px 23px;
  /* border: 1px solid #f2f2f2; */
  object-fit: contain;
  top: -35px;
  position: relative;
  width: 100% !important;
  height: 100%;
}

.overviewSettings p,
.dateTime p {
  margin-top: 29px;
}

input#file {
  opacity: 0;
}

.file-custom {
  position: absolute;
  width: 356px;
  top: -2px;
  right: 0;
  left: -2px;
  z-index: 5;
  padding: 7px 11px 8px 11px;
  background-color: #fff;
  border: solid 2px #eaeaea;
  border-radius: 3px;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 15px;
  font-weight: normal;
  color: #131414;
}

.file-custom:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: 'Choose Image';
  padding: 7px 11px 8px 11px;
  background-color: #f2f3f5;
  border: solid 1px #d6e2e4;
  font-size: 15px;
  font-weight: 600;
  color: #131414;
}

.file-custom:after {
  content: 'Choose image (.jpg, .png)';
  opacity: 0.5;
}

.a-divStyle {
  padding: 26px 24px;
}

.a-divStyle a {
  padding: 7px 11px 8px 0;
  font-size: 15px;
  font-weight: 600;
  color: #00829b;
  text-decoration: none;
}

.bord-bott1 {
  border-bottom: 1px solid #d6e2e4;
}

.bord-bott2 {
  border-bottom: 2px solid #f2f2f2;
}

.displayColors > div:first-of-type {
  padding: 12px 24px 36px 24px;
}

.displayColors h3 {
  color: #222222;
}

.displayColors .color > div {
  padding: 16px 0;
}

.color-width {
  width: 48px;
  height: 48px;
  border-radius: 3px;
  margin-right: 24px;
  cursor: pointer;
}

.reordering-flex {
  width: 45%;
  justify-content: flex-end;
}

.subtitle {
  line-height: 1.33;
  font-size: 24px;
  letter-spacing: 0.01px;
  color: #131414;
  margin-bottom: 16px;
}

.color-picker-wrapper {
  position: absolute;
  top: 55px;
  z-index: 99;
}

.green {
  background-color: #00b980;
}

.red {
  background-color: #b92d00;
}

.yellow {
  background-color: #f3f3df;
}

.btn-style {
  padding: 7px 11px 8px 11px;
  border-radius: 3px;
  border: none;
  background-color: #ffffff !important;
  font-size: 15px;
  font-weight: 600;
  color: #9d9d9d;
  cursor: pointer;
  outline: none;
}

.blue-reset {
  color: #00829b;
}

.slideSettings h1 {
  padding: 40px 0 32px 0;
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 0.01px;
  color: #131414;
}

.slideDuration > div {
  padding: 32px 24px;
}

.slideDuration p {
  line-height: 1.3;
}

.slideDuration h3 {
  padding-bottom: 8px;
}

.slideDuration select {
  width: 384px;
  height: 38px;
  padding-bottom: 0;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: rgba(255, 255, 255, 0.4);
  font-size: 15px;
  font-weight: normal;
  color: #131414;
  margin-bottom: 24px;
}

.dateTime h3 {
  padding-bottom: 12px;
}

.dateTime h4 {
  font-size: 18px;
  font-weight: normal;
  color: #131414;
  padding-left: 14px;
  padding-top: 5px;
}

.dateTime input[type='text'] {
  padding: 4px 5px 8px 5px;
  margin: 0 12px;
  cursor: pointer;
  border-radius: 3px;
  border: solid 2px #eaeaea;
  background-color: #eaeaea;
  width: 30px;
}

.single-sub-device:first-child:not(.sub-group-device) {
  padding-top: 32px;
}

.single-sub-device:last-child:not(.sub-group-device) {
  margin-bottom: 150px;
}

.sub-licenses {
  box-shadow: none;
}

.sub-licenses > form > div:nth-child(1) {
  flex-direction: column;
}

.sub-group-device {
  background-color: #f2f4f5;
  margin-top: 32px;
}

.sub-group-device h2 {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #222;
}

.sub-group-device p {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.single-sub-device {
  padding: 32px 0 32px 0;
}

.single-sub-device:not(:first-child) {
  border-top: 2px solid #f2f2f2;
}

.single-sub-device.has-groups{
  padding-bottom: 0;
} 
                    

.room-section-footer-messaging {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.room-section-footer-messaging .a-divStyle {
  padding-left: 13px !important;
}

.messaging-item {
  display: flex;
  flex-direction: column;
}

.messaging-item span {
  align-self: flex-end;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #fe2c12;
}

.dateTime-div {
  padding: 32px 24px;
}

.dateTime-div-add-kiosk {
  padding-bottom: 24px;
}

.checkbox-div > div {
  display: flex;
  align-items: center;
}

.interval-settings .checkbox-div > div:nth-child(3) label::before {
  top: -4px;
}

.bg-black {
  width: 384px;
  height: 246px;
  background-color: #1d1f21;
  object-fit: contain;
}

.settings-logo-wrapper {
  width: 200px;
  height: 200px;
  margin-right: 125px;
}

main.dashboard {
  width: 100%;
  margin: 0 auto;
  max-width: unset;
}

.form-overview input[type='text']:focus,
.form-overview textarea:focus {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  border: solid 2px #00829b;
}

.form-overview input[type='text']:hover:not(:read-only),
.form-overview textarea:hover:not(:disabled) {
  background-color: #f2f3f5;
}

.form-overview .reset-changes:not(:disabled):hover {
  background-color: #e3e4e8;
}

.readonly-overview div > div > div > h3 {
  margin-bottom: 4px;
}

.readonly-overview div:first-child > div:first-child {
  column-gap: 14px;
}

.readonly-overview div > div > div.floor-div-kiosk {
  gap: 17px;
}

.readonly-overview div:nth-child(3) > div:first-child {
  gap: 6px;
}

.readonly-overview > div:first-child div.wheelchair-div > div.wheelchair-select p {
  margin-left: 0;
}

.manual-record-desc {
  margin-top: 10px;
}

/* radio styling */

.radio-item.manual-enabled {
  margin-right: 28px;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  display: flex;
  align-items: center;
  color: #131414;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
}

.radio-item label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 0.5px;
  left: -2px;
  margin: 0 5px 0 0;
  width: 18px;
  height: 18px;
  border-radius: 11px;
  border: 2px solid #eaeaea;
  background-color: transparent;
  cursor: pointer;
}

.radio-item input[type='radio']:checked + label:after {
  border-radius: 11px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 12px;
  left: 10px;
  content: ' ';
  display: block;
  background: #00829b;
}

.radio-item input[type='radio']:checked + label:before {
  border: 2px solid #00829b;
}

.radio-item input[type='radio']:disabled:checked + label:before {
  border-color: #abc0c4;
}

.radio-item input[type='radio']:disabled:checked + label:after {
  background-color: #abc0c4;
}

.signage-slides-wrapper table tr td:nth-child(2) {
  width: 20%;
}

.signage-slides-wrapper table tr td:nth-child(2) div {
  width: 80%;
}

/* .whatLogo {
      width: 640px;
} */

.whatLogo > section,
.whatLogo > footer {
  padding: 32px;
}

.whatLogo > section {
  border-bottom: 2px solid #f2f2f2;
}

.whatLogo > section a {
  color: #00829b;
  letter-spacing: 0.01px;
  font-size: 20px;
  font-weight: 500;
  text-decoration: underline;
}

.whatLogo h2 {
  font-size: 28px;
  font-weight: 600;
  color: #131414;
  margin-bottom: 32px;
}

.whatLogo p {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.01px;
  color: #000000;
  line-height: 1.3;
}

.whatLogo img {
  width: 100%;
  object-fit: contain;
  margin: 32px 0 10px 0;
}

.whatLogo img.additional {
  margin-top: 0;
}

.whatLogo h6 {
  font-size: 15px;
  font-weight: normal;
  color: #727272;
  margin-bottom: 32px;
}

.whatLogo h3 {
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  margin-bottom: 10px;
}

.whatLogo ul {
  padding-left: 28px;
  list-style: disc;
  margin-bottom: 90px;
}

.whatLogo div.not-list {
  margin-bottom: 90px;
}

.whatLogo ul li {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: 0.01px;
  color: #000000;
}

.slide-detail-overview {
  margin-bottom: 16px;
}

.slide-detail-overview h2 {
  margin-bottom: 0;
}

.whatisfor-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  background: #fff;
  border-top: 2px solid rgba(58, 58, 58, 0.06);
}

.whatLogo > footer button {
  padding: 9px 20px;
  border-radius: 3px;
  background-color: #00829b;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.delete-warning > p {
  letter-spacing: 0.01px;
  text-align: left;
  font-size: 20px;
  font-weight: 300;
  color: #000000;
  margin-bottom: 32px;
}

.delete-warning > div {
  padding: 32px;
  border: solid 1px #d6e2e4;
  border-top: 4px solid #fe2c12;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.04);
}

.warning-content p {
  font-size: 20px;
  color: #131414;
  font-weight: 600;
  text-align: left;
}

.add-slide-title {
  margin-left: 24px;
}

.react-tel-input .form-control {
  height: 32px !important;
  border: solid 2px #eaeaea !important;
  border-radius: 3px !important;
  width: 100% !important;
}

.empty-state-link {
  font-size: 20px;
  font-weight: 600;
  color: #00829b;
  text-decoration: underline !important;
}

.empty-state-link:active {
  color: #00829b;
}

.connect-account-header {
  padding-bottom: 0;
}

.connect-account-header h1 {
  font-size: 28px;
  font-weight: 600;
  color: #131414;
}

.connect-account-header:not(.twofa-remove) > div > div {
  margin-bottom: 32px;
}

.connect-account-header a {
  font-size: 20px;
  text-decoration: underline;
  margin-top: 4px;
}

.connect-account-header:not(.twofa) > div {
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
}

.twofa:not(.twofa-remove) > div > div > p:nth-child(1) {
  margin-bottom: 26px;
}

.twofa-remove + form {
  width: unset;
}

.twofa-remove + form > div:nth-child(1) {
  padding-left: 32px;
}

.twofa-footer > button:nth-child(1) {
  margin-right: 16px;
}

.disabled-sso h3,
.disabled-sso p {
  color: #9d9d9d;
}

.disabled-sso button {
  background-color: #abc0c4;
}

.SSO-login {
  max-width: 348px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SSO-login p {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #727272;
  margin-bottom: 34px;
}

.sso-roles p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.SSO-login > a {
  width: 100%;
}

.SSO-login button {
  margin-bottom: 24px;
  width: 100%;
  justify-content: center;
  padding: 10px 46px;
}

.SSO-login-required-page {
  padding-bottom: 18px;
}

.SSO-login_login-page {
  padding-bottom: 24px;
}

.SSO-login-required-page button {
  margin: auto;
  height: 100%;
  max-width: 320px;
  padding: 10px 44px 9px;
}

.user-actions-sso p {
  line-height: 1.3;
}

.or {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
  width: 100%;
}

.or .or-dash {
  height: 2px;
  background-color: #eaeaea;
  width: 133px;
}

.or .or-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #727272;
  padding: 0 16px;
}

.signin-sso-required {
  align-items: flex-start;
}

.signin-sso-required .copyright {
  margin-top: 24px;
}

.signin-sso-required .logo {
  margin-bottom: 87px;
}

.signin-sso-required .links {
  justify-content: space-around;
  margin-top: 7px;
}

.signin-sso-required .links a {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #727272;
  text-decoration: underline;
}

.signin.twofa-login > .div {
  margin-top: 24px;
}

.signin.twofa-login > .div .logo {
  margin-bottom: 24px;
}

.signin.twofa-login > .div > div {
  max-width: 320px;
}

.twofa-sso {
  padding-bottom: 36px;
}

.twofa-sso > div {
  border-bottom: 2px solid #f2f2f2;
  margin: 0 24px;
  padding: 24px 0;
}

.twofa-login .step {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
  margin-right: 24px;
}

.twofa-login p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.twofa-single-user {
  padding-top: 32px;
}

.twofa-single-user h3 {
  margin-bottom: 8px;
}

.twofa-login .link--default {
  font-size: 20px;
}

.twofa-login img {
  padding: 24px 0;
}

.twofa-login h5 {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.twofa-login a.forgot-password-link {
  padding-top: 0;
}

.twofa-login .SSO-login p {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #727272;
}

.twofa-steps > div:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.twofa-steps input {
  max-width: 300px;
}

.connect-account-header h4 {
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  padding-bottom: 10px;
  padding-top: 0;
}

.connect-account-header p {
  font-size: 20px;
  letter-spacing: 0.01px;
  font-weight: 300;
  color: #000000;
}

.connect-account-header + div.padd32 ul {
  padding-left: 20px;
  list-style-type: disc;
}

.connect-account-header + div.padd32 ul li {
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.connect-account-header + div.padd32 ul li strong {
  font-weight: 600;
}

.connect-account-header + div.padd32 h4 {
  padding-bottom: 10px;
  padding-top: 0;
  font-size: 20px;
  font-weight: 600;
  color: #222222;
}

.connect-account-optional-step {
  margin-bottom: 0;
  position: relative;
  top: -32px;
  flex-basis: 29%;
}

span {
  color: #222222;
  font-size: 20px;
  font-weight: 600;
}

-desc p:nth-child(1) {
  margin-bottom: 20px;
}

.designated-input {
  border-bottom: none;
}

.designated-input label {
  color: #131414;
}

.designated-title h1 {
  font-size: 28px;
}

.designated-input-wrapper {
  padding-top: 0;
}

-desc p {
  font-weight: 300;
  color: #000000;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 0.01px;
}

-desc {
  padding-bottom: 32px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  width: 76%;
}

.device-title .user-actions,
.header--dark .user-actions {
  padding-top: 0;
}

.my-account-mobile {
  display: none;
}

/* KIosks */

.wheelchair-select {
  margin-top: 5px !important;
}

div > .edit-kiosk:not(:nth-child(3)) {
  border-bottom: none;
  padding-bottom: 0;
}

.optional-field {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.edit-kiosk-file {
  margin-bottom: 100px;
}

.edit-kiosk-file h4 {
  padding-top: 12px;
}

.edit-kiosk-file .file-heading {
  padding-top: 0;
  font-size: 20px;
  line-height: 1.3;
}

.file-desc {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.expired-sub {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #f2f3f5 !important;
}

/* .expired-sub .sub-border {
      background-color: #ebeced !important;
} */

#request-invite {
  cursor: pointer;
}

.not-assined-sub .sub-border {
  background-color: transparent !important;
}

.filter-rows {
  width: calc(100% - 48px);
  display: flex;
  justify-content: flex-start;
  background-color: #eff4f5;
  max-height: 80px;
  align-items: flex-start;
  padding: 24px 24px 24px 24px;
}

.filter-item {
  padding-top: 0;
}

.filter-mobile {
  padding-top: 24px;
}

.filter-list.filter-mobile button {
  color: #131414;
  border: solid 1px #4d5e61;
  background-color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

.filter-list.reset-all button {
  background-color: transparent;
  color: #4d5e61;
  border: none;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
}

.filter-item .filter-wrapper {
  flex: unset;
}

.filter-item .filter-list {
  margin-top: 0;
  margin-left: 0;
}

.filter-rows > div {
  max-width: 284px;
  flex: 1 0 30%;
  margin-right: 16px;
}

.filter-rows .open-time-custom {
  margin-bottom: 0;
  width: 95%;
  border: none;
  height: 32px;
  min-height: 32px;
  /* border: solid 2px #eaeaea; */
}

.filter-rows .open-time-custom > div {
  transition: none;
  border: none;
  min-height: 32px;
  height: 32px;
  /* border: solid 2px #eaeaea; */
}

.filter-rows .open-time-custom > div > div {
  top: -2px;
}

.filter-rows .open-time-custom div[class*='-singleValue'] {
  font-size: 15px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.filter-header-table {
  border-bottom: none;
}

.filter-header-table > input {
  width: 95% !important;
}

.color-section-room {
  padding: 29px 0 20px 0 !important;
}

.color-section-room span {
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: left;
  color: #404040;
}

.signage-assignments {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 24px !important;
  margin-left: 24px !important;
  border-top: 2px solid rgba(58, 58, 58, 0.06);
}

.signage-assignments > div {
  padding-right: 0;
  padding-left: 0;
}

.signage-assignments > div:nth-child(1) {
  flex-basis: 40%;
}

.signage-assignments h3 {
  color: #222222;
  margin-bottom: 22px;
}

.signage-assignments .single-signage {
  margin-bottom: 40px;
}

.signage-assignments .single-signage:last-of-type {
  padding-bottom: 40px;
}

.signage-assignments .single-signage p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
  margin-bottom: 6px;
}

.signage-assignments .single-signage span {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 13px;
}

.assignments-wrapper {
  padding-right: 0;
}

.assignments {
  margin-bottom: 22px;
  font-family: ProximaNova;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.assignments-wrapper p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.empty-state-message {
  text-align: left;
}

.kiosk-room-floors select {
  width: 360px;
  padding: 4.5px 0 4.5px 11px;
}

.sections-what-kiosks-do div:nth-child(1) {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 2px solid rgba(58, 58, 58, 0.06);
}

.what-settings-do div:nth-child(1) {
  margin-bottom: 32px;
}

.kiosk-mode-footer {
  padding-left: 0 !important;
}

.filter-list {
  margin-top: 24px;
  margin-left: 4px;
  display: inline-block;
  position: relative;
}

.filter-list button {
  border-radius: 3px;
  border: solid 1px #364344;
  background-color: #4d5e61;
  font-size: 13.5px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: 0.14px;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px 25px 5px 8px;
}

.filter-list img {
  position: absolute;
  top: 1px;
  right: 3px;
  cursor: pointer;
}

.kiosk-add-group {
  top: 45px;
  left: -70px;
}

.kiosk-groups > div:first-child {
  flex-basis: 60%;
}

.kiosk-group-actions {
  margin-bottom: 32px;
}

.toggle-product-visibility-main p {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.toggle-product-visibility-main p:nth-child(2) {
  font-weight: 300;
}

.toggle-product-visibility-status {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.kiosk-visibility-title {
  box-shadow: -3px 0 6px 0 rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid #ffffff;
  padding-bottom: 32px;
}

.hidden-product,
.hidden-product p {
  color: #9d9d9d;
}

.visible-heading {
  margin-bottom: 10px;
}

.remove-active-sigange-assignment {
  padding-top: 16px !important;
}

.remove-active-sigange-assignment button:hover {
  background-color: #f2f3f5;
}

.custom-footer {
  padding: 33px 21px 9px 21px;
}

.floor-page h4 {
  padding-top: 0;
}

.floor-page > div:not(:nth-child(1)) {
  padding-top: 32px;
  margin-top: 32px;
  border-top: 2px solid #ebeced;
}

.floor-assignments {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.css-26l3qy-menu > div {
  z-index: 9999;
  background: #fff;
  cursor: pointer !important;
}

.filter-cancel {
  margin-right: 16px;
}

.scm-desc {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
  border-bottom: 2px solid #ebeced;
  padding-bottom: 32px;
}

.scm-image {
  max-width: 576px;
  max-height: 266px;
}

.organizationOverview-section > form.add-digital-signage > div > div {
  padding-top: 16px;
}

.slide-page--heading {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
  margin-bottom: 22px;
}

.slide-page-heading-wrapper {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 2px solid #ebebeb;
}

main section:not(.welcome-get):not(.device-subs):last-of-type {
  margin-bottom: 64px;
}

.kiosk-messaging-section {
  padding-top: 0 !important;
}

.double-signage > div .section-table {
  margin-bottom: 48px !important;
}

.toogle-panel-top {
  bottom: 40px;
  top: unset;
}

.toogle-arrow-top::after {
  transform: rotate(180deg);
  border-right: solid 1px #d6e2e4;
  border-left: none !important;
  top: 0 !important;
  right: 0px !important;
}

.organizationOverview-section > form > div > div.add-slide-name {
  padding: 0 32px 32px !important;
}

.organizationOverview-section > form.add-slide-form > div > div {
  padding: 32px;
}

.file-uploader {
  height: 32px;
}

.add-slide-form h4 {
  margin-top: 0;
}

.organizationOverview-section > form.add-slide-form .add-slide-info {
  padding-bottom: 0;
}

.toggle-product-visibility-main p.product-info-additional {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
  margin-top: 12px;
}

.device-overview.overview-section form > div:nth-child(2) {
  padding: 0 24px;
}

.device-overview.overview-section form textarea {
  width: 100%;
}

.device-step-2 > a {
  margin-right: 16px;
}

.addLicensing-footer {
  justify-content: space-between;
}

.addLicensing-footer.roles-editor a {
  margin-left: 0;
}

.addLicensing-footer.roles-editor > div:nth-child(2) > div > div:nth-child(1) {
  margin-right: 16px;
}

.direct-licensing {
  width: 100%;
}

.room-main-info-wrapper {
  margin-bottom: 100px;
}

.existing-data p {
  padding-bottom: 0;
}

.campus-page > div {
  padding-top: 0 !important;
}

.group-overview > div:not(.custom-group > div):nth-child(1) {
  padding-bottom: 0;
}

.room-page-buildings {
  margin-bottom: 0;
}

.error-message-room-features {
  margin-bottom: 0;
}

.add-tech-stack {
  position: relative;
  top: 32px;
  height: 31.4px;
}

.signage-notes {
  padding-left: 24px;
}

.optional-step h3 {
  margin-bottom: 0;
}

.connect-account-optional-step-desc {
  margin-top: 32px;
  padding-bottom: 32px;
  width: 100%;
  border-bottom: 2px solid #ebebeb;
}

.connect-account-optional-step-desc p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.connect-account-optional-step-desc p:nth-child(1) {
  margin-bottom: 23px;
}

.signage-assinments-groups-details {
  margin-bottom: 100px;
}

.empty-campuses {
  padding: 13px 13px 13px 0;
}

.occupancy-restrictions-campuses-flyout-header {
  margin-top: 32px;
  border-top: solid 2px #f2f2f2;
  padding-top: 32px;
}

.occupancy-restrictions-campuses-flyout-header > .input-range {
  margin-top: 14px;
}

.occupancy-restrictions-campuses-flyout {
  display: flex;
  flex-direction: column;
  padding-top: 31px;
  margin-top: 31px;
  border-top: solid 2px #f2f2f2;
}

.occupancy-restrictions-campuses-flyout > div {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.occupancy-restrictions-campuses-flyout span {
  margin-bottom: 22px;
}

.occupancy-restrictions-campuses-flyout p:not(.standart-p) {
  margin-bottom: 13px;
  font-size: 15px;
}

.occupancy-restrictions-campuses-flyout p.standart-p {
  margin-bottom: 6px;
}

.occupancy-restrictions-campuses-flyout a {
  width: fit-content;
  color: #00829b;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
}

.occupancy-restrictions-campuses-flyout-input {
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  gap: 8px;
}

.occupancy-restrictions-campuses-flyout-input input {
  box-sizing: border-box;
  height: 32px;
  max-width: 266px;
  max-height: 32px;
  border: solid 2px #eaeaea;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 7px 11px 8px 11px;
}

.campus-flyout-occupancy-section-inner {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.overview-white-card > section {
  margin-bottom: 40px !important;
}

.custom-hours span {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.kiosk-configuration-mode .radio-item input[type='radio']:checked + label::after {
  left: 10px;
  top: 8px;
}

.checkbox-table input {
  position: relative;
  bottom: -3px;
}

.checkbox-table input.all-rows {
  bottom: 0;
  left: 10px;
}

/* RTW updates */

.kiosk-type {
  padding-bottom: 32px;
  border-bottom: 2px solid rgba(58, 58, 58, 0.06);
}

.kiosk-type-detail.cont-div > div {
  flex: 0 0 30%;
}

.rwt-add-building {
  margin-bottom: 82px;
}

.rtw-buildng {
  padding-top: 32px;
  border-top: 2px solid rgba(58, 58, 58, 0.06);
}

.rtw-buildng p {
  margin-top: 24px;
  border-bottom: 2px solid rgba(58, 58, 58, 0.06);
}

.rtw-buildng .open-time-custom.custom-hours {
  margin-bottom: 0;
  width: 360px;
  border: none;
}

.rtw-buildng .optional-field {
  margin-left: 6px;
}

.name-address .cont-div input {
  max-width: 384px;
}

.name-address .open-time-custom.custom-hours {
  width: 100%;
  height: 32px;
  margin-bottom: 10px;
}

.react-select-sizing {
  width: 360px;
}

.room-features_hidden-input {
  display: none;
}

.rtw-verified span {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #727272;
  margin-left: 6px;
}

.rtw-verified img {
  width: 18px !important;
}

.name-address .login--label {
  margin-bottom: 16px;
}

.edit-admin p.rwt-connected {
  padding-bottom: 0;
  margin-top: 24px;
}

.rwt-connected .link--default {
  font-size: 20px;
}

.row-count-show .row-count-wrapper span {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #131414;
}

.row-count-show #react-select-2-option-0 {
  background: transparent;
}

.text-bold {
  font-weight: bold;
}

.rtw-connect-desc p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.rtw-connect-desc > p:nth-child(1) {
  margin-bottom: 32px;
}

.rtw-connect-desc span {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.workspace-assignments {
  background-color: #fbfbfb;
}

.workspaces-mapped {
  padding-top: 0;
}

.workspaces-mapped p {
  margin-bottom: 0;
}

.disabled-seat {
  pointer-events: none;
  cursor: pointer;
  background-color: #d6e2e4;
}

.rtw-connect-list {
  padding-top: 0 !important;
}

.rtw-connect-list h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #222222;
}

.rtw-connect-list li {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.rtw-connect-list span {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.disconnect-confiration {
  margin-top: 16px;
}

.finalyze-rtw {
  margin-top: 32px;
}

.finalyze-rtw > div {
  border-bottom: 2px solid rgba(58, 58, 58, 0.06);
}

.finalyze-desc p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.remove-border-bot2-underline {
  border-bottom: 0;
}

.finalyze-list ul {
  list-style-type: none;
}

/* Kiosk settings */

.kiosks-settings .overview-h2 {
  padding-top: 48px;
  padding-bottom: 16px;
}

.kiosks-settings .overview-h2 p {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 0.01px;
  text-align: left;
  color: #000000;
}

.kiosks-settings .checkbox-div .radio-item:nth-child(2) {
  margin-left: 34px;
}

.kiosks-settings .radio-item label::before {
  margin: 0 14px 0 0;
}

.kiosks-settings > div {
  padding-right: 0;
  padding-left: 0;
}

.kiosks-settings .input-range {
  max-width: 577px;
}

.kiosks-settings .input-range__track--active {
  background-color: #00829b;
}

.disable-range .input-range__slider {
  display: none;
}

.kiosks-settings .input-range__slider {
  background-color: #fff;
  background-image: url(../../Images/icon-drag.svg);
  background-size: contain;
  border: none;
  width: 28.4px;
  height: 26.4px;
  margin-top: -1rem;
  border: 1px solid #eaeaea;
}

.kiosks-settings .header-v1-input-text {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
  flex-grow: 1;
  max-width: 266px;
}

.kiosks-settings .header-v1-input-text input {
  width: 93%;
}

.kiosks-settings .dash {
  align-self: center;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 30px;
}

.kiosks-settings .input-range-custom {
  padding-top: 26px;
}

.kiosks-settings .input-range-custom label {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #131414;
}

.rooms-section form > div > div.temp-range-wrapper {
  padding-bottom: 0;
}

.kiosks-settings .temp-range {
  flex-grow: 1;
}

.range-in-settings .temp-range {
  margin-bottom: 32px;
}

.release-slider {
  display: flex;
  max-width: 577px;
}

.kiosks-settings .rooms-section {
  margin-bottom: 0 !important;
}

.kiosks-settings .header-table {
  margin-top: 0;
}

.kiosks-settings .rooms-section-footer > div {
  padding-right: 10px !important;
}

.temp-range-title > h3 {
  padding-bottom: 14px;
}

.flyout-settings > div {
  flex-direction: column;
}

.flyout-settings .input-range {
  max-width: 548px;
}

.flyout-campus-settings-list select {
  max-width: 360px;
}

.flyout-range {
  padding-bottom: 0;
}

.flyout-range > div {
  padding-bottom: 32px;
}

.flyout-settings .new-kiosk-mode {
  margin-bottom: 16px;
}

.flyout-settings .temp-range + div button {
  padding-left: 0;
}

.flyout-settings .temp-range + div {
  margin-top: 31px;
}

.floor-div-kiosk select.area-select {
  width: 360px !important;
}

.settings-button-wrapper {
  margin-bottom: 48px;
  margin-top: 17px;
  display: block;
}

.settings-button-wrapper button {
  min-height: 36px !important;
  padding: 9px 18px !important;
}

.settings-button-wrapper button::before {
  height: 36px !important;
}

.workspace-settings-btn {
  margin-top: 16px;
  margin-bottom: 32px;
}

.workspace-settings-btn > button {
  max-width: 202px;
}

.integrationO356 {
  height: 32px;
  padding: 7px 20px;
}

.editor-wrapper {
  padding: 40px 0 32px 30px;
}
.tox-tinymce {
  border: 1px solid #c8c6c3 !important;
}
.tox:not([dir='rtl']) {
  margin-top: 8px;
}
.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid #c8c6c3 !important;
}
.tox .tox-statusbar {
  border-top: 1px solid #c8c6c3 !important;
}
.tox .tox-tbtn svg {
  display: block !important;
  fill: #78999f !important;
}
.tox .tox-tbtn--enabled svg,
.tox .tox-tbtn--enabled:hover svg {
  fill: #78999f !important;
}

.tox .tox-tbtn--enabled:hover svg,
.tox .tox-tbtn--enabled svg,
.tox .tox-tbtn svg {
  fill: #78999f !important;
}

.tox :not(svg):not(rect) {
  color: #131414 !important;
}

.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:hover {
  background: #eaeaea !important;
  border: 0 !important;
  box-shadow: none !important;
  color: #78999f !important;
}
.tox .tox-tbtn--disabled svg,
.tox .tox-tbtn--disabled:hover svg,
.tox .tox-tbtn:disabled svg,
.tox .tox-tbtn:disabled:hover svg {
  fill: rgba(19, 20, 20, 0.25) !important;
}
.tox .tox-tbtn {
  font-family: 'proxima-nova' !important;
}
.tox .tox-tbtn:hover {
  background: #eaeaea !important;
}

.editor-label {
  margin-bottom: 8px;
}

.react-tel-input .country-list .search.phone-input-search{
  padding: 10px 0 6px 0;
}

.react-tel-input .country-list .phone-input-search input.search-box{
  padding: 11px;
}

@media only screen and (min-width: 1599px) {
  .workspace-type-detail.cont-div > div {
    flex: 0 0 360px;
    justify-content: unset;
    margin-right: 70px;
  }
  .floor-div-workspace {
    margin-right: 32px;
    min-width: 361px;
  }
  .workspace-assignments .kiosk-select {
    max-width: unset;
  }
  .workspace-assignments .kiosk-select div:first-child {
    margin-right: 30px;
  }
  .workspace-assignments select {
    max-width: 360px;
  }

  .kiosk-phone-wrapper {
    position: relative;
    left: -17px;
  }
}

@media only screen and (max-height: 856px) {
  .sidebar-settings {
    position: relative;
    bottom: 15px;
  }
}

@media only screen and (max-width: 1366px) {
  main.user-table,
  .organizationSettings-main {
    width: 96%;
    margin: 0 2%;
  }
  main.user-table.dashboard {
    width: 100%;
    margin: 0;
  }
  .welcome-section > div,
  .welcome-get > div {
    padding: 0 2%;
    width: 96%;
  }
}

@media only screen and (max-width: 1280px) {
  .meetingRoom {
    width: 100%;
  }
  .admin-logo img {
    width: auto;
  }
}

@media only screen and (max-width: 900px) {
  .getAccount {
    grid-template-columns: 1fr 1fr;
  }
  .myAccount {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 550px) {
  .getAccount {
    grid-template-columns: 1fr;
  }
  .myAccount {
    grid-template-columns: 1fr;
  }
  .deviceHealthy {
    width: 100%;
  }
}

@media only screen and (min-height: 1300px) {
  .open-nav {
    height: 91% !important;
  }
}

@media only screen and (min-width: 1024px) {
  .getAccount > div:not(:nth-child(3n - 2)) {
    margin-left: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .cont-div > div:not(:last-child) {
    margin-right: 13px;
  }
  .floor-div-workspace {
    margin-right: 29px;
  }
  .wheelchair-div,
  .building {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .getAccount > div:not(:nth-child(2n-1)) {
    margin-left: 16px;
  }
  .getAccount > div {
    max-width: unset;
    flex-basis: 33%;
  }
  .get-div > div:first-of-type {
    flex-direction: column;
  }
  .get-div > div:first-of-type > div:nth-child(1) {
    margin-bottom: 16px;
  }
  .get-div > div:first-of-type > div:nth-child(2) {
    margin-bottom: 16px;
  }
  .getAccount > div {
    max-width: unset;
  }
  .getAccount > div:nth-child(even) {
    margin-left: 16px;
  }
  .admin-logo {
    width: 100%;
  }
  .admin-logo > div {
    flex-grow: 1;
  }
  /* .admin-logo>a {
            flex-grow: 1;
      } */
  li.sidebar-active > a {
    background-image: unset;
  }
  li.active-sidebar-link,
  .sidebar-settings li {
    background-image: unset !important;
  }
  .block-scroll {
    padding-top: 0 !important;
    /* overflow: hidden !important; */
  }
  .overlay-active-mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(47, 47, 47, 0.6);
    z-index: 996;
    overflow: hidden;
  }
  .open-nav {
    max-width: 260px !important;
    position: absolute !important;
    z-index: 999;
    height: 90%;
    top: unset !important;
    right: 0;
  }
  .open-nav .sidebar-menu li > div {
    padding: 12px 0 12px 25px;
  }
  .account-items-mobile li > div {
    padding: 15px 0 15px 25px !important;
  }
  .sidebar-menu li > div {
    padding: 0;
  }
  .organizationSettings-main {
    width: 96%;
    margin: 0 2%;
  }
  #sidebar {
    max-width: 0;
    box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.08);
  }
  main.user-table {
    width: 96%;
    margin: 0 2%;
  }
  .admin-logo {
    /* height: unset;
            width: 240px;
            align-items: center; */
    display: flex;
  }
  .icon-nav {
    margin-right: 20px;
    display: flex;
    cursor: pointer;
    height: unset;
    align-items: center;
  }
  .icon-nav > img {
    width: 32px;
    height: -moz-available;
    /* For Chrome */
    /* width: -webkit-fill-available;
            width: stretch; */
  }
  @media (hover: hover) {
    .icon-nav > img:hover {
      background-color: #f2f3f5;
    }
  }
  .my-account-mobile {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .my-account-mobile div a {
    padding: 0;
  }
  .my-account-mobile {
    border-top: 1px solid rgba(101, 120, 123, 1);
    padding-top: 12px;
    margin-top: 12px;
  }
  .my-account-mobile .email {
    opacity: 0.6;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    padding: 12px 24px 12px 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sidebar-menu {
    flex-grow: 0;
  }
  .admin-toggle-profile {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .contoso {
    width: 100%;
  }
  .file-custom {
    width: 90%;
  }
}

@media only screen and (max-width: 864px) {
  .building {
    padding-left: 0;
  }
  .notes textarea {
    width: 100%;
  }
  .building {
    padding-left: 0;
  }
  .campus select {
    width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .rooms-section .cont-div input {
    width: 100%;
  }
  .campus select {
    max-width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #flyout {
    width: 100%;
  }
  .flyout-footer.roles-editor {
    width: 90%;
  }
  .getAccount > div {
    flex-basis: 80%;
    max-width: unset;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 550px) {
  .cont-div input,
  .cont-div select {
    width: 100%;
  }
  .getAccount > div:nth-child(even) {
    margin-left: 0;
  }
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes toggleBorder {
  0% {
    border: 5px solid white;
  }
  100% {
    border: unset;
  }
}

@media only screen and (max-width: 768px) {
  .error-main > div {
    padding-left: 0;
  }
}

.filter-rows .filter-wrapper-item {
  max-width: 215px;
}